import React from "react";
import { makeStyles, useTheme, styled } from "@material-ui/core/styles";
import { Fade, Grid } from "@material-ui/core";
import useMediaQuery from "@material-ui/core/useMediaQuery";

import Divider from "../components/Divider"
import Button from "@material-ui/core/Button";
import Card from '@material-ui/core/Card';
import grow from "../assets/pricing/grow.png";
import spark from "../assets/pricing/spark.png";

import CheckRoundedIcon from '@material-ui/icons/CheckRounded'; 
import CheckIcon from '@material-ui/icons/Check';
import Typography from "@material-ui/core/Typography";

import { useHistory } from "react-router-dom";



const useStyles = makeStyles((theme) => ({
  bodyWrapper: {
    color: '#000',
    fontSize: '1vw',
    lineheight: '1.5',
  },
  newContainer:{
    justifyContent: "space-between",
  //  width: "100%",
    maxWidth: "1920px",
    marginLeft: "auto",
    marginRight: "auto",
    paddingLeft: "13.3em",
    paddingRight: "13.3em",
    display: "block",
    position: "relative",
  },
pricingHeroSection:{
  paddingTop: '11.11em',
 // paddingBottom: '3.06em',
  [theme.breakpoints.down("md")]: {
    paddingTop: '110px',

    },
    [theme.breakpoints.down("sm")]: {
      paddingBottom: '0',
      }
},
pricingHeroContainer:{
  textAlign: "center",
  flexDirection: "column",
  justifyContent: "flex-start",
  alignItems: "center",
  paddingLeft: "10.5em",
  paddingRight: "10.5em",
  display: "flex",
  [theme.breakpoints.down("md")]: {
    paddingLeft: '5%',
    paddingRight: '5%',

    },
    [theme.breakpoints.down("sm")]: {
      flexDirection: 'column',
      }
},
pricingHeaderWrapper:{
  flexDirection: "column",
  justifyContent: "center ",
  alignItems: "center: ",
  width: "80%",
  paddingLeft: "0",
  display: "flex",
  [theme.breakpoints.down("sm")]: {
    alignItems: "center",
    width: "100%",
    marginLeft: "auto",
    marginRight: "auto",
    paddingLeft: "0",
    }

},
h1NewBig:{
  color: '#000',
  fontSize: '3.2em',
  fontWeight: '700',
  lineHeight: '1.18',
},
h1Pricing:{
  fontSize: '65px',
  fontFamily: 'Poppins-Bold',
  [theme.breakpoints.down("md")]: {
    fontSize: '64px',
    },
  [theme.breakpoints.down("sm")]: {
    fontSize: '50px',
    textAlign: 'center',
    }
},
pricingBody:{
  marginTop: '1em',
  marginBottom: '1.43em',
  fontSize: '1.6em',
  width:'65%',
  marginLeft: "auto",
  marginRight: "auto",
  color:"#0097B2",
  fontFamily: 'Inter, sans-serif',
 // fontSize: '1.94em',
  lineHeight: '1.2',
  [theme.breakpoints.down("md")]: {
    fontSize: '18px',
    },
  [theme.breakpoints.down("sm")]: {
    width: 'auto',
    maxWidth: '254px',
    fontSize: '16px',
    textAlign: 'center',
    marginBottom: '0',
 
    }
},

sectionPricing:{
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
 // paddingBottom: "14em",
  display: "flex",
  position: "relative",
  overflow: "hidden",

},
containerPricing:{
  flexDirection: "column",
  justifyContent: "flex-start",
  alignItems: "center",
 // width: "100%",
  paddingLeft: "5em",
  paddingRight: "5em",
  display: "flex",
  position: "relative",
  [theme.breakpoints.down("md")]: {
    paddingLeft: '5%',
    paddingRight: '5%',
}
  
},
pricingToggleContainer:{
  flexDirection: "column",
  alignItems: "center",
  display: "flex",
  position: "relative",
  [theme.breakpoints.down("sm")]: {
    marginTop: '30px',
}
  
},
pricingToggleWrapper:{
  justifyContent: "center",
  alignItems: "center",
  marginBottom: "26px",
  marginLeft: "auto",
  marginRight: "auto",
  display: "flex",
  [theme.breakpoints.down("sm")]: {
    marginTop: '30px',
}
},
toggleTitle:{
  justifyContent: "center",
  width: "120px",
  padding: "10px",
  fontSize: "16px",
  fontWeight: "700",
  lineHeight: "1",
  display: "flex",
},
toggleButton:{
  backgroundColor: "#C4D82E",
  cursor: "pointer",
  borderRadius: "50ch",
  flexDirection: "column",
  alignItems: "flex-start",
  width: "60px",
  height: "20px",
  padding: "4px",
  display: "flex",

},
toggleCircle:{
  backgroundColor: "#000",
  borderRadius: "50%",
  width: "22px",
  height: "22px",
  transition: "all .15s ease-in-out",
  pointerEvents: 'none'

},
pricingArrow:{
  color: "#108daa",
    width: "26px",
    height: "28px",
    marginBottom: "8px",
    marginLeft: "55px",
    position: "absolute",
    top: "auto",
    bottom: "0%",
    left: "50%",

},
pricingDescription:{
  color: "#108daa",
  fontFamily: 'Inter-Bold, sans-serif',
  fontSize: '14px',
  fontWeight: '600',

},

pricingCollection:{
  width: '100%',

},
pricingCardWrapper:{
  gridColumnGap: "16px",
  gridRowGap: "16px",
  gridTemplateRows: "auto",
  gridTemplateColumns: "1fr 1fr",
  gridAutoColumns: "1fr",
  justifyContent: "space-between",
  width: "100%",
  maxWidth: "none",
  marginTop: "60px",
  display: "flex",

  [theme.breakpoints.down("sm")]: {
    flexDirection: 'column',
    marginTop: '47px',
},
[theme.breakpoints.down("md")]: {
  flexDirection: 'column',
  alignItems: 'center',
  width: '100%',
  display: 'flex',
}
},
pricingCard:{
  backgroundColor: "#dbeaed",
  borderRadius: "20px",
  flexDirection: "column",
  justifyContent: "space-between",
  width: "49%",
  padding: "40px",
  display: "flex",
  position: "relative",
  [theme.breakpoints.down("md")]: {
    width: '60%',
    marginBottom: '36px',
    padding: '24px',
  },
  [theme.breakpoints.down("sm")]: {
    width: '85%',
},

},
pricingCardTop:{
  paddingTop: '10px',
},
imagePricing: {
 // width: '100%',
  height: '160px',
  borderRadius:"50%",
  marginBottom: '24px',
  verticalAlign: 'middle',
  maxWidth: '100%',
  display: 'block',
  marginLeft: 'auto',
  marginRight: 'auto',
},
h3Pricing:{
  textAlign: "center",
  marginTop: "0",
  marginBottom: "0",
  fontSize: "24px",
  lineHeight: "1.25",
  [theme.breakpoints.down("sm")]: {
    textAlign: 'left',
    fontSize: '20px',
  },
  

},
textPricing:{
  fontFamily: "Inter, sans-serif",
  fontSize: "18px",
  lineHeight: "1.44",
  marginTop: "5px",
  textAlign: "center",
  [theme.breakpoints.down("md")]: {
    textAlign: 'left',
    
  },
},
pricingList:{
  marginTop: '20px',
  paddingLeft: '0',
  listStyle: 'none',
},
pricingItem:{
  alignItems: 'flex-start',
  marginTop: '0',
  marginBottom: '10px',
  display: 'flex',

},
checkIcon:{
  flex: 'none',
  width: '16px',
  height: '16px',
  marginTop: '3px',
  marginRight: '14px',
  stroke:"#C4D82E",
  strokeWidth: '3',
},
textList:{
  fontFamily: "Inter, sans-serif",
  fontSize: "16px",
  lineHeight: "1.5",
  textAlign: 'left',
  [theme.breakpoints.down("sm")]: {
    fontSize: "14px",
  },
 
},

pricingCardBottom:{
  flexDirection: 'column',
  alignItems: 'center',
  marginTop: '40px',
  display: 'flex',
  [theme.breakpoints.down("sm")]: {
   // alignItems: 'flex-start',  
  
  },
},
priceWrapper:{
  justifyContent: 'center',
  marginBottom: '21px',
  transition: 'all .5s cubic-bezier(.25,.46,.45,.94)',
  display: 'flex',
  position: 'relative',
},
buttonPrimary:{
  backgroundColor: "#000",
    color: " #fff",
    cursor: " pointer",
    border: " 1px solid #000",
    borderRadius: " .5em",
    justifyContent: " center",
    alignItems: " center",
    height: " 44px",
    padding: " 10px 16px",
    fontSize: " 14px",
    fontWeight: " 700",
    textDecoration: " none",
    transition: " all .4s",
    display: " flex",
    position: " relative",
    "&:hover": {
      backgroundColor: 'black',
      color: 'white',
    },
  
},
buttonQuarterly:{
  backgroundColor: "#C4D82E",
  color: "#000",
  textAlign: "center",
  borderWidth: "0",
  width: "80%",
  height: "auto",
  paddingTop: "21px",
  paddingBottom: "21px",
  fontSize: "16px",
  [theme.breakpoints.down("sm")]: {
    paddingTop: '17px',
    paddingBottom: '17px',
  
  },
},
buttonAnnually:{
  backgroundColor: "#C4D82E",
  color: "#000",
  textAlign: "center",
  borderWidth: "0",
  width: "80%",
  height: "auto",
  paddingTop: "21px",
  paddingBottom: "21px",
  fontSize: "16px",
  display: "none",
  [theme.breakpoints.down("sm")]: {
    paddingTop: '17px',
    paddingBottom: '17px',
  
  },

},

priceWrapperAnnually:{
  opacity: '0',
  transition: 'opacity .4s',
  display: 'flex',
},
priceSpan:{
  opacity: " 1",
  fontSize: " 16px",
  fontWeight: " 600",
  lineHeight: " 30px",
  textDecoration: " none",
  display: " inline",
},
activeWrapper:{
//textAlign: 'center',
},
inactiveWrapper:{
  marginRight: '8px',

},
priceQuarterly:{
  flex: " none",
  fontSize: " 44px",
  fontWeight: " 700",
  lineHeight: " 30px",
  fontFamily:"Poppins-Bold",
  transition: " opacity .5s cubic-bezier(.25,.46,.45,.94)",
  display: " inline",
},
priceQuarterlyInactive:{
  color: '#c4c4c4',
  textDecoration: 'line-through',
  position: 'static',
},
priceAnnually:{

},
priceAnnuallyInactive:{

},
priceWrapperQuarterly2:{
  zIndex: " 100",
  opacity: " 1",
  transition: " opacity .4s linear",
  display: " flex",
  position: " absolute",
 
},
pricingBottomSub:{
color: '#8B8787',
fontSize: '18px',
padding:"20px",
},


subRow:{
  display: "-webkit-box",
  display: "-ms-flexbox",
  display: "flex",
  msFlexWrap: "wrap",
  flexWrap: "wrap",
  marginLeft: "auto",
  marginRight: "auto",
  maxWidth: "1336px",
  width: "98%",
},
subscriptionFaqRow: {
  borderTop: "1px dashed #c8cdd6",
  margin: "8rem auto auto",
 // maxWidth: 830px;
  maxWidth: "83rem",
  paddingBottom: "10rem",
  paddingTop: "5rem",
  width: "60%",
  "& ul": {
    [theme.breakpoints.down("sm")]: {
      padding:"0"
    
    },
  },
  [theme.breakpoints.down("sm")]: {
    width:"90%",
  
  },

},
faqHeader:{
  fontSize: "1.6rem",
  margin:"0 0 1rem",
  fontFamily: "Poppins",
  fontWeight:700,
  [theme.breakpoints.down("sm")]: {
    fontSize:"24px",
    fontWeight:"600"
  },
},
faqBody:{
  color: "#0a1c2e",
  fontFamily: "Inter",
  fontSize: "16px",
  fontSize: "1rem",
  lineHeight: "1.5",
  fontWeight: "400",
  marginBottom: "1.5rem",
  [theme.breakpoints.down("sm")]: {
    fontSize: "16px",
  
  },
},

faqTextContainer:{
  marginTop: "1rem",
  marginBottom: "3rem",

},

}));


const Pricing = () => {
  const url = 'https://business.getklippit.com?new=true';
  const sparkPlan = `${url}&plan=https://buy.stripe.com/28obJa3UCfSb0PmbIJ`;
  const sparkPlanYr = `${url}&plan=https://buy.stripe.com/dR600s9eW21l41y8wy`;

  const classes = useStyles();
  const theme = useTheme();
  const matchesSM = useMediaQuery(theme.breakpoints.down("sm"));
  const history = useHistory();

  return (
 <Grid className={classes.bodyWrapper}>
  <Grid className={classes.pricingHeroSection}>
    <Grid className={` ${classes.pricingHeroContainer} ${classes.newContainer}`}>
      <Grid className={classes.pricingHeaderWrapper}>
      <h1 className={` ${classes.h1NewBig} ${classes.h1Pricing}`}>Start your free 14–day trial</h1>
      <p className={` ${classes.pricingBody}`} style={{textAlign:"center"}}>We believe influencer marketing should be accessible to every company, no matter the size.<br></br><br></br>  Get started in less than 60 seconds</p>
      </Grid>
    </Grid>
  </Grid>
  <Grid className={classes.sectionPricing} >
      <Grid className={` ${classes.containerPricing} ${classes.newContainer}`}>
     {/*   <Grid className={classes.pricingToggleContainer}>
            <Grid className={classes.pricingToggleWrapper}>
              <Grid className={classes.toggleTitle}>
                <div>Monthly</div>
              </Grid>
              <Grid className={classes.toggleButton}>
              <div class={classes.toggleCircle}></div>
              </Grid>
              <Grid className={classes.toggleTitle}>
              <div>Quarterly</div>
              </Grid>
            </Grid>
            <Grid className={classes.pricingDescription}>
            10% discount
          </Grid> 
          <Grid className={classes.pricingArrow}>
          <svg width="100%"  viewBox="0 0 27 29" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M1 26.85C0.641015 26.85 0.35 27.141 0.35 27.5C0.35 27.859 0.641015 28.15 1 28.15V26.85ZM21.9596 0.540381C21.7058 0.28654 21.2942 0.28654 21.0404 0.540381L16.9038 4.67696C16.65 4.9308 16.65 5.34235 16.9038 5.59619C17.1576 5.85003 17.5692 5.85003 17.823 5.59619L21.5 1.91924L25.177 5.59619C25.4308 5.85003 25.8424 5.85003 26.0962 5.59619C26.35 5.34235 26.35 4.9308 26.0962 4.67696L21.9596 0.540381ZM1 28.15C10.9298 28.15 22.15 18.7838 22.15 1H20.85C20.85 18.2162 10.0702 26.85 1 26.85V28.15Z" fill="CurrentColor"></path>
</svg>
          </Grid>
          </Grid>
*/}
          <Grid className={classes.pricingCollection}>
            <Grid role="list" className={classes.pricingCardWrapper}> 
              <Grid role="listitem" className={classes.pricingCard}>
                <div className={classes.pricingCardTop}>
                <img loading="lazy" src={spark} alt="" className={classes.imagePricing} ></img>
                <div id="spark" className={classes.pricingWrapper} >
                    <div className={classes.priceWrapperAnnually}>
                     {/*} <div className={classes.inactiveWrapper}>
                          <div className={`${classes.priceQuarterly} ${classes.priceQuarterlyInactive} `}>
                          $</div>
                          <div className={`${classes.priceQuarterly} ${classes.priceQuarterlyInactive} `}>
                            1,078
                            </div>
                          </div>*/}
                            <div className={classes.activeWrapper}>
                              <div className={classes.priceQuarterly}>$</div>
                              <div className={classes.priceQuarterly}>
                                538</div>
                                <div className={classes.priceSpan}>
                                  &nbsp;/3 mo</div>
                                  </div>
                                  </div>
                                  <aside className={classes.priceWrapperQuarterly} style={{textAlign:"center"}}>
                                    <div className={classes.priceQuarterly}>
                                      Free Trial</div>
                                      {  /*   <div className={classes.priceQuarterly}>
                                        199</div>
                                   <div className={classes.priceSpan}>
                                          &nbsp;per month</div>*/}
                                          </aside>
                                          </div>
                <Typography className={classes.pricingList} component="ul" style={{ listStyleType: 'none', paddingLeft: 0,fontFamily: "Inter", }}>
                    <li className={classes.pricingItem}><CheckRoundedIcon className={classes.checkIcon} /> <p className={classes.textList}>1 free influencer shoutout</p></li>

                    <li className={classes.pricingItem}><CheckRoundedIcon className={classes.checkIcon} /> <p className={classes.textList}>Default Campaign Parameters</p></li>

                    <li className={classes.pricingItem}><CheckRoundedIcon className={classes.checkIcon} /> <p className={classes.textList}>QR code material for placement with tracking & analytics
(3 shipped to you)</p></li>

                    <li className={classes.pricingItem}><CheckRoundedIcon className={classes.checkIcon} /> <p className={classes.textList}>3 pre-programmed NFC short range tags
(shipped to you)</p></li>

                    <li className={classes.pricingItem}><CheckRoundedIcon className={classes.checkIcon} /> <p className={classes.textList}>Personalized Merchant Dashboard</p></li>

                    <li className={classes.pricingItem}><CheckRoundedIcon className={classes.checkIcon} /> <p className={classes.textList}>Unlock Downloadable Influencer Content</p></li>
                    
                  </Typography>
                
                
                </div>
                <div  className={classes.pricingCardBottom}>
               <div className={classes.pricingBottomSub}>
               After 14 Days: $199
               </div>
                                          <a href={sparkPlan}  className={`${classes.buttonPrimary} ${classes.buttonQuarterly} `}><div>Start my free trial</div></a><a href={sparkPlanYr} className={` ${classes.buttonPrimary} ${classes.buttonAnnually}`}><div>Try it free for two weeks</div></a></div>
            </Grid>


            <Grid role="listitem" className={classes.pricingCard}>
                <div className={classes.pricingCardTop}>
                <img loading="lazy" src={grow} alt="" className={classes.imagePricing} ></img>
                <div className={classes.pricingWrapper} >
                    <div className={classes.priceWrapperAnnually}>
                    
                            <div className={classes.activeWrapper}>
                              <div className={classes.priceQuarterly}>$</div>
                              <div className={classes.priceQuarterly}>
                                1078</div>
                                <div className={classes.priceSpan}>
                                  &nbsp;/3 mo</div>
                                  </div>
                                  </div>
                                  <aside className={classes.priceWrapperQuarterly} style={{textAlign:"center"}}>
                                    <div className={classes.priceQuarterly}>
                                      $</div>
                                      <div className={classes.priceQuarterly}>
                                        399</div>
                                        <div className={classes.priceSpan}>
                                          &nbsp;&nbsp;/ &nbsp;mo</div>
                                          </aside>
                                          </div>
                <Typography className={classes.pricingList} component="ul" style={{ listStyleType: 'none', paddingLeft: 0,fontFamily: "Inter", }}>
                    <li className={classes.pricingItem}><CheckRoundedIcon className={classes.checkIcon} /> <p className={classes.textList}>3 free influencer shoutout</p></li>

                    <li className={classes.pricingItem}><CheckRoundedIcon className={classes.checkIcon} /> <p className={classes.textList}>Customize Campaign Parameters</p></li>

                    <li className={classes.pricingItem}><CheckRoundedIcon className={classes.checkIcon} /> <p className={classes.textList}>QR code material for placement with tracking & analytics
(6 shipped to you)</p></li>

                    <li className={classes.pricingItem}><CheckRoundedIcon className={classes.checkIcon} /> <p className={classes.textList}>6 pre-programmed NFC long range tags
(shipped to you)</p></li>

                    <li className={classes.pricingItem}><CheckRoundedIcon className={classes.checkIcon} /> <p className={classes.textList}>Personalized Merchant Dashboard</p></li>

                    <li className={classes.pricingItem}><CheckRoundedIcon className={classes.checkIcon} /> <p className={classes.textList}><b>Simultaneous Campaigns</b></p></li>

                    <li className={classes.pricingItem}><CheckRoundedIcon className={classes.checkIcon} /> <p className={classes.textList}> <b>VIP Account Concierge Support</b></p></li>

                    
                  </Typography>
                
                
                </div>
                <div  className={classes.pricingCardBottom}>
                
                                          <a  onClick={() => {window.open('https://calendly.com/klippit/klippit-merchant-onboarding/30min', '_blank')}}  className={`${classes.buttonPrimary} ${classes.buttonQuarterly} `}><div>Book a call</div></a><a onClick={() => {window.open('https://calendly.com/klippit/klippit-merchant-onboarding/30min', '_blank')}} className={` ${classes.buttonPrimary} ${classes.buttonAnnually}`}><div>Book a call</div></a></div>
            </Grid>
        </Grid>
      </Grid>
    </Grid>
 </Grid>
 <Grid>
 <Grid  className={`  ${classes.subRow} ${classes.subscriptionFaqRow}`} >
    <ul style={{listStyle: "none"}}>
      <li className={classes.faqTextContainer}>
        <h3 className={classes.faqHeader}>Why do you need my credit card for a free trial?</h3>
        <p className={classes.faqBody}>Entering your card information allows us to ensure you never lose a moment of tracking valuable data as your trial comes to an end and your subscription starts. It also helps us reduce fraud in our system and keep offering low prices for high-value returns to you.</p>
      </li>
      <li className={classes.faqTextContainer}>
        <h3 className={classes.faqHeader}>You may see a $1 USD pending transaction on your credit card statement, here's why:</h3>
        <p className={classes.faqBody}>The $1 USD charge is what is known as a validation pending transaction. It is not an actual charge but a pending transaction on your card that falls off within a day or two. Your credit card company does this to validate your card with our service.</p>
      </li>
      <li className={classes.faqTextContainer}>
        <h3 className={classes.faqHeader}>How do I avoid being charged at the end of my trial?</h3>
        <p className={classes.faqBody}>While we know you will find value in using our service, we understand that you may need approval for the fee. And therefore need to cancel. To avoid being charged at the end of your trial, cancel 48 hours in advance.</p>
      </li>
    </ul>
  </Grid>
  </Grid>
  </Grid>
  );
};

export default Pricing;
