import React from "react";
import { useState } from "react";
import { useHistory } from "react-router-dom";

import KlippitIcon from "./KlippitIcon";


const Privacy = () => {
    const privacyText = `
Privacy Policy

Effective date: 5/18/2023

At Klippit, we respect and value the privacy of our users. This Privacy Policy outlines how we collect, use, and protect your personal information when you visit our website and use our services. By accessing or using Klippit, you signify your consent to the terms of this Privacy Policy.

Information We Collect
We may collect certain personal information from you when you interact with our website or use our services. This may include your name, email address, phone number, and other relevant information that you voluntarily provide to us.

How We Use Your Information
We use the collected information to improve and personalize your experience with Klippit. This includes providing you with relevant offers, promotions, and discounts, as well as communicating with you regarding our services. We may also use your information for research and analytics purposes to enhance and optimize our platform.

Information Sharing
We do not sell, trade, or rent your personal information to third parties without your explicit consent. However, we may share certain information with trusted partners and service providers who assist us in delivering our services. These parties are contractually obligated to maintain the confidentiality and security of your information.

Data Security
We take appropriate measures to protect your personal information from unauthorized access, alteration, disclosure, or destruction. However, please note that no data transmission or storage method can guarantee absolute security. We strive to use industry-standard security protocols to safeguard your information, but we cannot guarantee its complete security.

Cookies and Tracking Technologies
We use cookies and similar tracking technologies to enhance your browsing experience and gather information about how you interact with our website. You have the option to disable cookies through your browser settings, but please note that this may limit certain functionalities of Klippit.

Third-Party Links
Our website may contain links to third-party websites or services that are not operated or controlled by Klippit. This Privacy Policy does not apply to such external sites. We encourage you to review the privacy policies of those third parties before providing any personal information.

Children's Privacy
Klippit is not intended for individuals under the age of 13. We do not knowingly collect personal information from children. If you believe that we have inadvertently collected information from a child, please contact us immediately.

Changes to the Privacy Policy
We reserve the right to update or modify this Privacy Policy at any time. Any changes will be effective upon posting the revised policy on our website. We encourage you to review this page periodically to stay informed about our privacy practices.

If you have any questions or concerns regarding this Privacy Policy or our practices, please contact us at support@klippitapp.com.

By using Klippit, you acknowledge that you have read, understood, and agree to the terms of this Privacy Policy.

    `

	return (
		<>
		<div style={{ width: "100vw", height: "95vh", margin: 'auto' }}>
                <div style={{ height: '100%', display: 'flex', flexWrap: 'nowrap', flexDirection: "column", alignItems: 'center',  overflow: 'scroll' ,margin: "32px 0px"}}>
		  			<div style={{ marginTop: '32px', padding: '4px', justifyContent: 'center', alignItems: 'center' }}>
						<KlippitIcon size={"80"}/>
					</div>
				<div className="container" style={{ marginTop: "32px", paddingLeft: "96px", paddingRight: "96px" }}>
					<h1 style={{ fontFamily: "Roboto-Lite", color: "#333" }}><b>Privacy Policy</b></h1>
					<br />
					<div style={{ fontSize: "14px"}} >
						{privacyText.split("\n\n").map((i,key) => {
            				return <div key={key}><br />{i}</div>;
        				})}
        			</div>
        			<br />
        			<br />
				</div>
			</div>
		</div>

		</>
	);
}

export default Privacy;
