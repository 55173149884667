import React from "react";
import { makeStyles, useTheme, styled } from "@material-ui/core/styles";
import { Fade, Grid } from "@material-ui/core";
import useMediaQuery from "@material-ui/core/useMediaQuery";

import Divider from "../components/Divider"
import Button from "@material-ui/core/Button";
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import Box from '@material-ui/core/Box';

import squareTiles from "../assets/homePage/homepage1.png";
import connectImg from "../assets/homePage/homefooter.png";
import mainButton from "../assets/homePage/main_button.png";
import roundSphere from "../assets/homePage/homepage2.png";

import step1Img from "../assets/Step1.png";
import step2Img from "../assets/Step2.png";
import step3Img from "../assets/Step3.png";
import step4Img from "../assets/Step4.png";
import step5Img from "../assets/Step5.png";
import step6Img from "../assets/Step6.png";
import step7Img from "../assets/Step7.png";

import equalLogo from "../assets/equal.png";

import Typography from "@material-ui/core/Typography";

import { useHistory } from "react-router-dom";

const HIW = "https://firebasestorage.googleapis.com/v0/b/klippit-97926.appspot.com/o/HIW.mp4?alt=media&token=2373fb37-80a9-4275-88a6-d3c932989e1b";

const GetStartedButton = styled(Button)({
  boxShadow: 'none',
  textTransform: 'none',
  fontSize: 16,
  //padding: '6px 12px',
  border: '1px solid',
  lineHeight: 1.5,
  width: "250px",
  height: "50px",
  backgroundColor: '#108DAA',
  borderColor: '#108DAA',
  color: '#fff',
  borderRadius: "30px",
  cursor: "pointer",
  whiteSpace:"nowrap",
  
 fontFamily: "Lato-Bold",
  '&:hover': {
    borderColor: '#108DAA',
    backgroundColor: '#108DAA',
    boxShadow: 'none',
  },
  
  '&:focus': {
    boxShadow: '0 0 0 0.2rem #108DAA',
    
  },
});
const useStyles = makeStyles((theme) => ({
  pageStyle:{
/*
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),*/
    paddingLeft:"180px",
    paddingRight:"180px",
    [theme.breakpoints.down("sm")]: {
    paddingLeft: "0px",
      paddingRight: "0px",
    }
  },
  container: {
    paddingTop: "60px",
    paddingBottom: "106px",
  
    [theme.breakpoints.down("sm")]: {
      paddingTop: "50px",
    },
  },
  footerContainer: {
    // paddingTop: "60px",
    // paddingBottom: "106px",
     backgroundColor: "#8ac5cc",
     height:"550px",
 color:"#fff",
   
     [theme.breakpoints.down("sm")]: {
       paddingTop: "50px",
       height:"100%",
     },
   },
  heading: {
    //filter: "drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.6))",
   fontFamily: "Arvo-Bold",
    paddingRight: "20px",
    width: "100%",
    fontSize: "40px",
    [theme.breakpoints.down("md")]: {
      fontSize: "36px",
     // textAlign: "center",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "30px",
      textAlign: "center",
    },
  },
  headerFooter: {
    //filter: "drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.6))",
   
   // paddingRight: "20px",
    fontSize: "24px",
    width:"90%",
    color:"#fff",
    fontWeight:400,
    fontFamily:"Lato",
    [theme.breakpoints.down("md")]: {
      fontSize: "24px",
      width:"100%",
     // textAlign: "center",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "20px",
      width:"100%",
      textAlign: "center",
    },
  },
  subHeading: {
   // filter: "drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.6))",
   lineHeight: "100% !Important",
fontFamily: "Droid-Sans",
   width:"90%",
    [theme.breakpoints.down("md")]: {
      fontSize: "15px",
      //textAlign: "center",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "12px",
      textAlign: "center",
      width:"100%",
    },
  },
  subHeaderFooter: {
    // filter: "drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.6))",
    width:"90%",
    color:"#fff",
    fontWeight:400,
    fontSize: "18px",
    fontFamily:"Lato",
     [theme.breakpoints.down("md")]: {
       fontSize: "20px",
       //textAlign: "center",
     },
     [theme.breakpoints.down("sm")]: {
       fontSize: "15px",
       textAlign: "center",
       width:"100%",
     },
   },
   
   
  rightTextContainer:{
   paddingLeft:"60px", 
   paddingRight:"60px",
   [theme.breakpoints.down("sm")]: {
    paddingLeft:"0px",
    paddingRight:"0px"
   },
  },
 newsSection:{
  paddingLeft: "128px", 
  paddingRight: "128px",

  [theme.breakpoints.down("md")]: {
    paddingLeft: "1.5em",
    paddingRight: "1.5em",
  },
  [theme.breakpoints.down("sm")]: {
    paddingLeft: "1.5em",
    paddingRight: "1.5em",
  },
 },
  
  iPhone: {
    width: "100%",
  },
  imageTiles: {
    width: "100%",
 
  },

  mainLeftContainer: {
   //paddingLeft: "4em",
    paddingRight: "2em",
    [theme.breakpoints.down("md")]: {
      paddingLeft: "1.5em",
      paddingRight: "1.5em",
    },
    [theme.breakpoints.down("sm")]: {
      paddingLeft: "1.5em",
      paddingRight: "1.5em",
    },
  },
  mainLeftContainerFooter: {
    color:"#fff",
   height:"100%",
    paddingLeft: "20em",
     paddingRight: "6em",
     [theme.breakpoints.down("md")]: {
       paddingLeft: "1.5em !Important",
       paddingRight: "1.5em !Important",
     },
     [theme.breakpoints.down("sm")]: {
       paddingLeft: "1.5em !Important",
       paddingRight: "1.5em !Important",
     },
   },
  mainRightContainer: {
   paddingLeft: "2em",
    //paddingRight: "4em",
    [theme.breakpoints.down("md")]: {
      paddingLeft: "1.5em",
      paddingRight: "1.5em",
    },
    [theme.breakpoints.down("sm")]: {
      paddingTop: "1.5em",
      paddingLeft: "1.5em",
      paddingRight: "1.5em",
    },
  },
  mainRightContainerFooter: {
    paddingLeft: "2em",
    height:"100%",
     //paddingRight: "4em",
     [theme.breakpoints.down("md")]: {
       paddingLeft: "1.5em",
      // paddingRight: "1.5em",
     },
     [theme.breakpoints.down("sm")]: {
       paddingTop: "1.5em",
       paddingLeft: "1.5em",
     paddingRight: "1.5em",
     alignItems:"center"
     },
   },
  testimonalContainer: {
    backgroundColor: "#8AC5CC",
    paddingTop: "66px",
    paddingLeft: "140px",
    paddingRight: "140px",
    [theme.breakpoints.down("sm")]: {
      paddingLeft: "30px",
      paddingRight: "30px",
    },
  },

}));


const HowItWorks = () => {
  const classes = useStyles();
  const theme = useTheme();
  const matchesSM = useMediaQuery(theme.breakpoints.down("sm"));
  const history = useHistory();

  return (
   <> <Grid container alignItems={"center"} direction={"column"} className={classes.pageStyle}>
      {/*PHOTO TILES*/}
      <Grid
        item
        container
        direction={matchesSM ? "column" : "row"}
        className={classes.container}

      >
        <Grid
          item
          lg={6}
          md={6}
          sm={12}
          xs={12}
          className={classes.mainLeftContainer}
        >
          <Grid
            item
            container
            alignItems={matchesSM ? null : "flex-end"}
            direction={"column"}
          >
            <Grid item>
              <Grid
                item
                container
                direction={"column"}
                alignItems={matchesSM ? "center" : null}
              >
                <Grid item>
                  <Typography variant={"h1"} className={classes.heading}>
                    How The Process Works
                  </Typography>
                </Grid>

                <Grid item style={{ marginTop: "42px" }}>
                  <Typography variant={"h6"} className={classes.subHeading}>
                    Learn how our approach redefines influencer marketing using your new or existing promotions.
                  <br /><br />
                  1. Create Promotion <br />
                  2. Incorporate QR code into your workflow <br />
                  3. Customers Create Content <br />
                  4. Manage influencers via Portal <br />
                  </Typography>
                </Grid>
                <Grid item style={{ marginTop: "42px" }}>
                  <GetStartedButton variant="contained"
                    style={{ top: 0 }}
                    onClick={() => history.push("/login")}>
                    Get Started
                  </GetStartedButton>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Grid
          item
          lg={6}
          md={6}
          sm={12}
          xs={12}
          className={classes.mainRightContainer}
          style={{ paddingTop: "40px" }}
        >

<iframe height='100%' width="100%" className={classes.imageTiles}  style={{aspectRatio: '2/1'}} src="https://www.youtube.com/embed/bWEQB5YLSt4?autoplay=1" title="How Klippit works!" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>

        </Grid>
      </Grid>




      <br />
      <br />

      {/*Steps Section*/}

      <Grid
        item
        container
        direction={matchesSM ? "column" : "row"}
        className={classes.container}

      >
        <Grid
          item
          container
          direction={"row"}
          style={{
            paddingBottom: "60px",
            backgroundColor: "#fff",
          }}

        >


          <Grid
            item
            lg={12}
            md={12}
            sm={12}
            xs={12}
            style={{ width: "100vw", paddingBottom: "80px", textAlign: "center" }}
            className={classes.newsSection}
          >
            <Grid item style={{ marginTop: "32px" }}>
              <Typography variant={"h2"}>
                Understanding the Steps to Success with Klippit
              </Typography>
            </Grid>

            <Grid item style={{ marginTop: "16px" }}>
              <Typography variant={"h6"}>
                See How Our Platform Transforms Your Promotions Into Real Results
              </Typography>
            </Grid>
          </Grid>

    <Grid sx={{ flexGrow: 1 }} container style={{ justifyContent: 'center' }} >
        <Grid container spacing={8} columns={{ xs: 2, sm: 4, md: 4, lg: 12 }} justifyContent="center">
            <Grid item xs={12} sm={6} md={4} lg={3}>
                <div>
                    <Card sx={{ maxWidth: 345 }} style={{ boxShadow: "none" }}>
                        <CardHeader
                          style={{ textAlign: 'center', fontSize: 17, fontWeight: 600 }}
                          title="Campaign Creation"
                          disableTypography
                        />
                        <div style={{ position: 'relative'  }}>
                        <CardMedia
                          component="img"
                          image={step1Img}
                          alt="Step 1 Image"
                          style={{
                            height: '250px',
                            objectFit: 'contain',
                            fontFamily:"Roboto-Lite",
                            boxSizing: 'border-box',
                          }}
                        />
                        </div>
                        <CardContent>
                          <Typography variant="body2" color="text.secondary">
                          Upload existing promotions or create new ones using the merchant portal. 
                          </Typography>
                        </CardContent>
                    </Card>
                </div>
            </Grid>

            <Grid item xs={12} sm={6} md={4} lg={3}>
                <div>
                    <Card sx={{ maxWidth: 345 }} style={{ boxShadow: "none" }}>
                        <CardHeader
                          style={{ textAlign: 'center', fontSize: 17, fontWeight: 600 }}
                          title="Place QR Code"
                          disableTypography
                        />
                        <div style={{ position: 'relative'  }}>
                        <CardMedia
                          component="img"
                          image={step2Img}
                          alt="Step 1 Image"
                          style={{
                            height: '250px',
                            objectFit: 'contain',
                            fontFamily:"Roboto-Lite",
                            boxSizing: 'border-box',
                          }}
                        />
                        </div>
                        <CardContent>
                          <Typography variant="body2" color="text.secondary">
                            The promotion is transformed into a QR code that can be printed out and placed in-store or sent digitally.
                          </Typography>
                        </CardContent>
                    </Card>
                </div>
            </Grid>

            <Grid item xs={12} sm={6} md={4} lg={3}>
                <div>
                    <Card sx={{ maxWidth: 345 }} style={{ boxShadow: "none" }}>
                        <CardHeader
                          style={{ textAlign: 'center', fontSize: 17, fontWeight: 600 }}
                          title="Customers Scan Code"
                          disableTypography
                        />
                        <div style={{ position: 'relative'  }}>
                        <CardMedia
                          component="img"
                          image={step3Img}
                          alt="Step 1 Image"
                          style={{
                            height: '250px',
                            objectFit: 'contain',
                            fontFamily:"Roboto-Lite",
                            boxSizing: 'border-box',
                          }}
                        />
                        </div>
                        <CardContent>
                          <Typography variant="body2" color="text.secondary">
                            Customers scan the QR code with their phone to unlock the promotion on their device.
                          </Typography>
                        </CardContent>
                    </Card>
                </div>
            </Grid>


            <Grid item xs={12} sm={6} md={4} lg={3}>
                <div>
                    <Card sx={{ maxWidth: 345 }} style={{ boxShadow: "none" }}>
                        <CardHeader
                          style={{ textAlign: 'center', fontSize: 17, fontWeight: 600 }}
                          title="Customers Become Influencers"
                          disableTypography
                        />
                        <div style={{ position: 'relative'  }}>
                        <CardMedia
                          component="img"
                          image={step4Img}
                          alt="Step 1 Image"
                          style={{
                            height: '250px',
                            objectFit: 'contain',
                            fontFamily:"Roboto-Lite",
                            boxSizing: 'border-box',
                          }}
                        />
                        </div>
                        <CardContent>
                          <Typography variant="body2" color="text.secondary">
                            Customers create real-time, genuine content on social media in exchange for the promotion.
                          </Typography>
                        </CardContent>
                    </Card>
                </div>
            </Grid>

            <Grid item xs={12} sm={6} md={4} lg={3}>
                <div>
                    <Card sx={{ maxWidth: 345 }} style={{ boxShadow: "none" }}>
                        <CardHeader
                          style={{ textAlign: 'center', fontSize: 17, fontWeight: 600 }}
                          title="Redeem Vouchers"
                          disableTypography
                        />
                        <div style={{ position: 'relative'  }}>
                        <CardMedia
                          component="img"
                          image={step5Img}
                          alt="Step 1 Image"
                          style={{
                            height: '250px',
                            objectFit: 'contain',
                            fontFamily:"Roboto-Lite",
                            boxSizing: 'border-box',
                          }}
                        />
                        </div>
                        <CardContent>
                          <Typography variant="body2" color="text.secondary">
                            The user's content becomes a redeemable coupon that can be validated in real-time.
                          </Typography>
                        </CardContent>
                    </Card>
                </div>
            </Grid>


            <Grid item xs={12} sm={6} md={4} lg={3}>
                <div>
                    <Card sx={{ maxWidth: 345 }} style={{ boxShadow: "none" }}>
                        <CardHeader
                          style={{ textAlign: 'center', fontSize: 17, fontWeight: 600 }}
                          title="Manage Campaign"
                          disableTypography
                        />
                        <div style={{ position: 'relative'  }}>
                        <CardMedia
                          component="img"
                          image={step6Img}
                          alt="Step 1 Image"
                          style={{
                            height: '250px',
                            objectFit: 'contain',
                            fontFamily:"Roboto-Lite",
                            boxSizing: 'border-box',
                          }}
                        />
                        </div>
                        <CardContent>
                          <Typography variant="body2" color="text.secondary">
                             Manage your campaign and track their performance
                             through the merchant portal.
                          </Typography>
                        </CardContent>
                    </Card>
                </div>
            </Grid>

            <Grid item xs={12} sm={6} md={4} lg={3}>
                <div>
                    <Card sx={{ maxWidth: 345 }} style={{ boxShadow: "none" }}>
                        <CardHeader
                          style={{ textAlign: 'center', fontSize: 17, fontWeight: 600 }}
                          title="Get More Customers"
                          disableTypography
                        />
                        <div style={{ position: 'relative'  }}>
                        <CardMedia
                          component="img"
                          image={step7Img}
                          alt="Step 1 Image"
                          style={{
                            height: '250px',
                            objectFit: 'contain',
                            fontFamily:"Roboto-Lite",
                            boxSizing: 'border-box',
                          }}
                        />
                        </div>
                        <CardContent>
                          <Typography variant="body2" color="text.secondary">
                            Customers can refer friends to the business and earn
                            additional rewards (on us).
                          </Typography>
                        </CardContent>
                    </Card>
                </div>
            </Grid>

            <Grid item xs={12} sm={6} md={4} lg={3}>
                <div>
                    <Card sx={{ maxWidth: 345 }} style={{ boxShadow: "none", overflow: 'visible' }}>
                        <CardContent style={{ transform: 'translate(0%,80%)', color: '#fff', textAlign: 'center', fontWeight: '700' }}>
                            <br/>
                        </CardContent>

                        <div style={{ width: '100%', height: '250px', position: 'relative', borderRadius: '50%', backgroundColor: '' }}>
                        <div style={{ position: 'absolute', width: '100%', height: '250px'}}>
                        <img
                            src={equalLogo}
                            alt="equal sign"
                            style={{
                                top: '112px',
                                left: '-16px',
                                zIndex: '1',
                                width: '25px',
                                height: '25px',
                                position: 'absolute',
                                transform: 'translate(0%, 0%)'
                            }}
                            />
                        </div>
                            <div style={{ transform: 'translate(10%,10%)',width: '200px', height: '200px', position: 'absolute', borderRadius: '50%', backgroundColor: '#ffc82c' }}>

                            
                        <CardContent style={{  transform: 'translate(0%,50%)', color: '#fff', textAlign: 'center', fontWeight: '700' }}>
                            Average $5.78 for every $1 spent on influencer marketing
                        </CardContent>
                        </div>
            
                        </div>
                    </Card>
                </div>
            </Grid>

    </Grid>


          {/*-----*/}
        </Grid>
      </Grid>
    </Grid>


      <br />
      <br />
      
      {/*Start Section*/}
      <div style={{ paddingTop: "100", alignItems: 'center' }}>
        <div style={{ borderBottomColor: '#bdbdbd', borderBottomWidth: "1", width: '90%' }} />
        <div style={{ position: 'relative' }}>
          <Divider />
        </div>
      </div>
      <Grid
        item
        container
        direction={matchesSM ? "column" : "row"}
        className={classes.container}

      >
        <Grid
          item
          container
          direction={"row"}
          style={{
            paddingBottom: "60px",
            backgroundColor: "#fff",
          }}

        >


          <Grid
            item
            lg={12}
            md={12}
            sm={12}
            xs={12}
            style={{ width: "100vw", paddingBottom: "80px", textAlign: "center" }}
            className={classes.newsSection}
          >
            <Grid item style={{ marginTop: "32px" }}>
              <Typography variant={"h2"} style={{ color: '#ffc82c' }}>
                Ready to Grow?

              </Typography>
            </Grid>
            <Grid item style={{ marginTop: "32px" }}>
                  <GetStartedButton variant="contained"
                    style={{ top: 0 }}
                    onClick={() => history.push("/login")}>
                    Get Started
                  </GetStartedButton>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
</Grid>


     {/*CONNECT*/}
     <Grid
     item
     container
     direction={matchesSM ? "column" : "row"}
     className={classes.footerContainer}
     
     >
    


     <Grid
       item
       lg={7}
       md={7}
       sm={12}
       xs={12}
       className={classes.mainLeftContainerFooter}
       
     >
       <Grid
         item
         container
         alignItems={matchesSM ? null : "flex-end"}
         
         direction={"column"}
       
       >
         <Grid item>
           <Grid item container alignItems={matchesSM ? "center" : null} direction={"column"} style={{color:"#fff"}}>
             
             <Grid item style={{ marginTop: "100px" }}>
               <Typography variant={"h4"} className={classes.headerFooter} >
               Go Beyond Deals. Become a Destination.

               </Typography>
             </Grid>

             <Grid item style={{ marginTop: "26px" }}>
               <Typography variant={"h6"}  className={classes.subHeaderFooter}>
               Klippit Merchant is your platform for becoming someone’s favorite place. Let’s work together to make you a destination.
               </Typography>
             </Grid>

             <Grid item style={{ marginTop: "50px" }}>
             <GetStartedButton variant="contained" 
         style={{top: 0, backgroundColor: "#fff", color:"#108DAA", fontWeight:600, borderColor:"#fff", width: "160px"}}
         onClick={() => history.push("/login")} > 
             Get Started
     </GetStartedButton>
     </Grid>

           </Grid>
         </Grid>
       </Grid>
       </Grid>
       <Grid
       item
      
       lg={5}
       md={5}
       sm={12}
       xs={12}
       className={classes.mainRightContainerFooter}
     >
       <img
         src={connectImg}
         alt="Connect Image"
         style={{height: "100%"}}
         className={classes.imageTiles}
       />
       
     </Grid>

   </Grid></>
  );
};

export default HowItWorks;
