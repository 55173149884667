import React, { Component } from "react";

class CalendlyCalendar extends Component {
  componentDidMount() {
    const head = document.querySelector('head');
    const script = document.createElement('script');
    script.setAttribute('src',  'https://assets.calendly.com/assets/external/widget.js');
    script.setAttribute('id',  'calendlyScript');
    head.appendChild(script);
  }  

  componentWillUnmount() {
    const el = document.getElementById('calendlyScript').outerHTML = "";
  }  
    
  render(){
    const calendlyUrl = `${process.env.REACT_APP_CALENDLY_URL}?hide_event_type_details=1&hide_gdpr_banner=1`
    return (
      <div>
        <div id="schedule_form">
          <div 
            className="calendly-inline-widget"
            data-url={calendlyUrl}
            style={{ minWidth: '320px', height: '540px' }} />
        </div>
      </div>
    );
  }
}

export default CalendlyCalendar
