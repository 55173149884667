import { createMuiTheme } from "@material-ui/core/styles";
import "./App.css";
import "./fonts/Arvo-Regular.ttf";
import "./fonts/Arvo-Bold.ttf";
import "./fonts/Arvo-BoldItalic.ttf";
import "./fonts/Arvo-Italic.ttf";
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';

const themePrimary = "#108DAA";
const themeAqua = "#8AC5CC";
const themeText = "#292D36";
//
// const textGrey = '#4d4d4d'
// const green = '#009900'
// const error = '#ff3333'

// Create a theme instance.
const theme = createMuiTheme({
  props: {
      // Name of the component
      MuiButtonBase: {
        // The properties to apply
        disableRipple: true // No more ripple, on the whole application!
      }
  },
  palette: {
    primary: { main: themePrimary },
    custom: {
      aqua: themeAqua,
    },
  },
  typography: {
    h1: {
      color: themeText,
     // fontFamily: "Roboto",
     fontFamily: "Poppins-Bold",
     fontWeight: "bold",
      fontSize: "40px",
      lineHeight: "140%",
    },
    h2: {
      color: themeText,
     // fontFamily: "Roboto",
     fontFamily: "Poppins-Bold",
      fontWeight: "bold",
      fontSize: "36px",
      lineHeight: "140%",
    },
    h3: {
      color: themeText,
     // fontFamily: "Montserrat",
     fontFamily: "Poppins",
      fontSize: "18px",
      lineHeight: "160%",
    },
    h4: {
      color: themeText,
     // fontFamily: "Montserrat",
     fontFamily: "Poppins-Bold",
      fontSize: "22px",
      fontWeight: 600,
      lineHeight: "160%",
    },

    h6: {
      color: "#666666",
     // fontFamily: "Roboto",
     fontFamily: "Poppins",
      fontSize: "18px",
      lineHeight: "160%",
    },
    subtitle1: {
      color: themeText,
     // fontFamily: "Roboto",
     fontFamily: "Poppins",
      fontSize: "16px",
      lineHeight: "140%",
    },
  },
  overrides: {
    MuiRating: {
      root: {
        color: themePrimary,
      },
    },
  },
});

export default theme;
