import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";

const FbConversionApi = () => {
  return (
    <Helmet>
      <script key="fbConversionApi" id="fb_conversion_api">
        {`
            function c_api(event, eventData) {
                let fbp = document.cookie.split(';')
                    .filter(c => c.includes('_fbp='))
                    .map(c => c.split('_fbp=')[1]);
                let fbc = document.cookie.split(';')
                    .filter(c => c.includes('_fbc='))
                    .map(c => c.split('_fbc=')[1]);
                fbp = (fbp.length && fbp[0]) || null;
                fbc = (fbc.length && fbc[0]) || null;
            
            	if(!fbc && window.location.search.includes('fbclid=')){
            		fbc = 'fb.1.'+ (+new Date()) +'.'+ window.location.search.split('fbclid=')[1];
            	}
            
                const headers = new Headers()
                headers.append("Content-Type", "application/json")
            
                const body = {
                    "event": event,
                    "event_data": eventData,
                    "fbp": fbp,
                    "fbclid": fbc,
                    "user_agent": navigator.userAgent,
                    "url": window.location.origin + window.location.pathname
            }
            
            const options = {
                method: "POST",
                headers,
                mode: "cors",
                body: JSON.stringify(body),
            }
            
                    fetch("${process.env.REACT_APP_DOMAIN}/fb_pixel", options)
                        .catch( (e) => { console.error(e) } )
            }
            c_api('PageView', null)
        `}
      </script>
    </Helmet>
  );
}


export const FbConversionEvent = (props) => {
  useEffect(() => {
      window.pixelEvent = props.eventName || "ViewContent";
      window.pixelData = props.data;
        setTimeout(function() {
            if (window.c_api === undefined) {
                return
            }
            window.c_api(window.pixelEvent, window.pixelData);
        }, 2000)
  }, [props.eventName, props.data])  
  return (
    <>
    </>
  );
}

export default FbConversionApi;
