import React from "react";


import { styled } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";


const ReadMoreButton = styled(Button)({
  boxShadow: 'none',
  textTransform: 'none',
  fontSize: 12,
  border: '1px solid',
  lineHeight: 1.5,
  width: '50%',
  backgroundColor: '#108DAA',
  borderColor: '#108DAA',
  color: '#fff',
  borderRadius: "20px",
  cursor: "pointer",
  left: '50%',
  bottom: '50%',
  transform: 'translate(-50%,50%)',
 
  
  fontFamily: [
    'Arvo',
   /* '-apple-system',
    'BlinkMacSystemFont',
    '"Segoe UI"',
    'Roboto',
    '"Helvetica Neue"',
    'Arial',
    'sans-serif',
    '"Apple Color Emoji"',
    '"Segoe UI Emoji"',
    '"Segoe UI Symbol"',*/
  ].join(','),
  '&:hover': {
    borderColor: '#108DAA',
    backgroundColor: '#108DAA',
    boxShadow: 'none',
  },
  
  '&:focus': {
    boxShadow: '0 0 0 0.2rem #108DAA',
    
  },
});


const BlogCard = (props) => {
    return (
        <>
            <div
                style={{
                    position: 'relative',
                    backgroundColor: '#fff',
                    height: "300px",
                    color: '#108DAA',
                    width: "250px",
                    '&:hover': {
                        backgroundColor: 'primary.main',
                        opacity: [0.9, 0.8, 0.7],
                    },
                    padding: "16px 0px",
                    boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)'
                }}
                >
                <div style={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
                    <div style={{ height: '100%', flex: '1' }}>
                        <div
                        style={{
                            position: "absolute",
                            backgroundColor: '#fff',
                            height: "50%",
                            right: "16px",
                            
                            left: "16px",
                            top: "-32px",
                            boxShadow: '0 4px 4px 0 rgba(0, 0, 0, 0), 0 1px 4px 0 rgba(0, 0, 0, 0.04)'
                        }}
                        >
                            <div style={{ height: '100%', width: '100%', overflow: 'hidden' }} >
                                <img src={props.image} style={{ paddingTop: '8px', position: 'relative', height: '100%', width: '100%', objectFit: 'contain' }} />
                            </div>
                            <div 
                                style={{
                                    position:  'absolute',
                                    height: '30px',
                                    width: '100%',
                                 
                                    bottom: '-8px'
                                }}
                            >
                                <ReadMoreButton variant="contained" onClick={props.handlePress}>
                                    Read More
                                </ReadMoreButton>
                            </div>
                        </div>

                    </div>
                    <div style={{ flex: '1', padding: '16px', overflow: 'hidden', color: '#108DAA', fontFamily:"Arvo-Bold"}}>
                        <div style={{ marginTop: '16px', paddingRight: "30px" }}>
                            <h4>{props.title}</h4>
                        </div>
                        <div style={{ marginTop: '32px', fontSize: '12px',color: "#47525E",fontFamily:"Arvo" }}>
                            <p>{props.subtitle}</p>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default BlogCard;
