import React,{ useState } from "react";
import { makeStyles, useTheme, styled } from "@material-ui/core/styles";
import { Fade, Grid } from "@material-ui/core";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Divider from "../components/Divider"
import { Button,  IconButton } from "@material-ui/core";
import Modal from '@material-ui/core/Modal';
import CheckRoundedIcon from '@material-ui/icons/CheckRounded'; 
import CloseIcon from '@material-ui/icons/Close';
//import TopIcon from '@material-ui/icons/VerticalAlignTopOutlined';
import { scroller } from 'react-scroll';
import squareTiles from "../assets/homePage/homepage1.png";
import engagement1 from "../assets/homePage/engagement1.png";
import engagement2 from "../assets/homePage/engagement2.png";
import circle1 from "../assets/homePage/circle1.png";
import circle2 from "../assets/homePage/circle2.png";
import circle3 from "../assets/homePage/circle3.png";
import brand1 from "../assets/homePage/brand1.png";
import brand2 from "../assets/homePage/brand2.png";
import brand3 from "../assets/homePage/brand3.png";
import brand4 from "../assets/homePage/brand4.png";
import brand5 from "../assets/homePage/brand5.png";
import brand6 from "../assets/homePage/brand6.png";
import brand7 from "../assets/homePage/brand7.png";
import brand8 from "../assets/homePage/brand8.png";
import step2 from "../assets/homePage/step2.png";
import step3 from "../assets/homePage/step3.png";
import step5 from "../assets/homePage/step5.png";

import number1 from "../assets/homePage/number1.png";
import number2 from "../assets/homePage/number2.png";
import number3 from "../assets/homePage/number3.png";
import number4 from "../assets/homePage/number4.png";
import number5 from "../assets/homePage/number5.png";
import heavyLift from "../assets/homePage/heavyLift.png";
import compareChart from "../assets/homePage/compareChart.png";
import example1 from "../assets/homePage/example1.png";
import example2 from "../assets/homePage/example2.png";
import example3 from "../assets/homePage/example3.png";

import { Box, Avatar, Container, Paper } from '@material-ui/core';
import homeVideoShot from "../assets/homePage/homeVideoShot.png";
import connectImg from "../assets/homePage/homefooter.png";
import mainButton from "../assets/homePage/main_button.png";
import roundSphere from "../assets/homePage/homepage2_2.png";
import yellowLine from "../assets/homePage/yellowLine.png";
import sectionBackground from "../assets/homePage/sectionBackground.png";
import iPhoneMockup from "../assets/homePage/iPhoneMockupHP.png";
import ChevronRightIcon from '@material-ui/icons/ChevronRightRounded';
// NEWS IMAGES
import news1Image from "../assets/homePage/news1.png";
import news2Image from "../assets/homePage/news2.png";
import news3Image from "../assets/homePage/news3.png";
import news4Image from "../assets/homePage/news4.png";
import news5Image from "../assets/homePage/news5.png";
import news6Image from "../assets/homePage/news6.png";

// TESTIMONAL IMAGES
import card1Image from "../assets/homePage/testimonals/card1.png";
import card2Image from "../assets/homePage/testimonals/card2.png";
import card3Image from "../assets/homePage/testimonals/card3.png";

/* Blog */
import BlogCard from "../components/BlogCard";

import Typography from "@material-ui/core/Typography";
import TestimonalCard from "../ui/TestimonalCard";

import { useHistory } from "react-router-dom";

const step1 = "https://firebasestorage.googleapis.com/v0/b/klippit-97926.appspot.com/o/step1.mp4?alt=media&token=6c5f2b1e-1df4-4361-b14b-f6aeab939d81";
const step4 = "https://firebasestorage.googleapis.com/v0/b/klippit-97926.appspot.com/o/step4.mp4?alt=media&token=6c018605-f44b-4894-853f-3dacc8df3509";
const homeGif = "https://firebasestorage.googleapis.com/v0/b/klippit-97926.appspot.com/o/homeGif.gif?alt=media&token=0f5646a0-a599-463a-b626-02c27e010c5e";
const homeGif2 = "https://firebasestorage.googleapis.com/v0/b/klippit-97926.appspot.com/o/homeGif2.gif?alt=media&token=784a3b6a-7d65-4c8f-99fc-7c26806d5b9a";
const homeVideo = "https://firebasestorage.googleapis.com/v0/b/klippit-97926.appspot.com/o/homeVideo.mp4?alt=media&token=63663f0a-164b-49f3-8399-7629f26c7a65";


const GetStartedButton = styled(Button)({
 /* boxShadow: 'none',
  textTransform: 'none',
  fontSize: 18,
  //padding: '6px 12px',
  border: '1px solid',
  lineHeight: 1.5,
  width: "250px",
  height: "50px",
  backgroundColor: '#108DAA',
  borderColor: '#108DAA',
  color: '#fff',
  borderRadius: "30px",
  cursor: "pointer",
  whiteSpace:"nowrap",
  
 fontFamily: "Lato-Bold",
  '&:hover': {
    borderColor: '#108DAA',
    backgroundColor: '#108DAA',
    boxShadow: 'none',
  },
  
  '&:focus': {
    boxShadow: '0 0 0 0.2rem #108DAA',
    
  },*/

  display: "-webkit-box",
  display: "-ms-flexbox",
  display: "flex",
  webkitBoxPack: "center",
  msFlexPack: "center",
  justifyContent: "center",
  webkitBoxAlign: "center",
  msFlexAlign: "center",
  alignItems: "center",
  width: "auto",
  height: "100%",
  paddingTop: "21px",
  paddingBottom: "21px",
  paddingLeft: "80px",
  paddingRight: "80px",
  color: "black",
  textDecoration: "none",
  textTransform:"none",
  fontSize:"20px",
  lineHeight:1,
  backgroundColor: '#C4D82E',
  fontFamily: "Poppins-Bold",
  "&:hover": {
    backgroundColor: 'black',
    color: 'white',
  },


});
const useStyles = makeStyles((theme) => ({
bookButton:{
  [theme.breakpoints.down("sm")]: {
    marginLeft: "auto",
      marginRight: "auto",
    },
},

  pageStyle:{
/*
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),*/
    paddingLeft:"100px",
    paddingRight:"100px",
    [theme.breakpoints.down("sm")]: {
    paddingLeft: "5%",
      paddingRight: "5%",
    }
  },
  heroContainer:{
    justifyContent: "space-between",
    paddingLeft: "5em",
    paddingRight: "5em",
    display: "flex",
  
    width: "100%",
    maxWidth: "1920px",
    marginLeft: "auto",
    marginRight: "auto",
    //paddingLeft: "13.3em",
  //  paddingRight: "13.3em",
  //  display: "block",
    position: "relative",

    [theme.breakpoints.down("sm")]: {
      flexDirection:" column",
      paddingLeft: "5%",
      paddingRight: "5%",
      display: "flex",
      }
   
  },
  heroWrapperText:{
    position: "relative",
  },
  heroWrapperImage:{
 
    justifyContent: "center",
    width: "40%",
    paddingRight: 0,
    display: "flex",
    [theme.breakpoints.up("md")]: {
         justifyContent: "flex-start",
    paddingLeft: "4.44em",
    paddingRight: 0,
    },
    [theme.breakpoints.down("sm")]: {
     // width:"100%"
      order: "-1",
      justifyContent: "center",
      width: "70%",
      marginBottom: "2em",
      marginLeft: "auto",
      marginRight: "auto",
      paddingLeft: "0",
    }

  },
  heroWrapper:{
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "flex-start",
    alignItems: " center",
    width:"40em",
    display: "flex",
    marginBottom: " 0",
   // position: "relative",
    paddingLeft: " 0",
    [theme.breakpoints.down("sm")]: {
      maxWidth: "500px", 
         width: " 100%",
         position: "relative",
         marginLeft: " auto",
        marginRight: " auto",
      }
  
  },
  videoContainer:{
    zIndex: "1",
    width: "auto",
    height: "100%",
    display: "block",


    color: "#fff",
    height: "700px",
    position: "relative",
   // overflow: "hidden",
   [theme.breakpoints.down("sm")]: {
    maxWidth: "500px", 
       width: " 100%",
       height: "400px",
       position: "relative",
       marginLeft: " auto",
      marginRight: " auto",
     
  
    }
  },
  videoStyle:{
    verticalAlign: "baseline",
    display: "inline-block",
    objectFit: "cover",
    zIndex: "-100",
    backgroundPosition: "50%",
    backgroundSize: "cover",
    width: "100%",
    height: "100%",
    margin: "auto",
   // position: "absolute",
    top: "-100%",
    bottom: "-100%",
    left: "-100%",
    right: "-100%",
  },
  testimonalContainer:{
    backgroundColor:"#FFF9EF",
    height: "fit-content",
    padding: "2.08em 3em",
    fontFamily: "Poppins-Bold",
    textAlign: "center",
    width:"50%",
   // height:"18em",
    borderRadius:"1.12em",
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    justifyContent: "center",
    [theme.breakpoints.down("md")]: {
      fontSize: "20px",
      width:"90% !Important",
    // paddingLeft: "0px !important",
     // textAlign: "center",
    },
    [theme.breakpoints.down("sm")]: {
      width:"90%",
      fontSize: "16px",
      }
  },
  testimonals:
  {
    fontSize:"25px",
    lineHeight: 1.33,
    fontWeight:700,
    fontFamily:"inherit",
    [theme.breakpoints.down("sm")]: {
 
      fontSize: "16px",
      }
  
  },
  modalContainer:{
    position: 'absolute',
     top: '50%',
      left: '50%', 
      transform: 'translate(-50%, -50%)', 
      width: 400, 
      bgcolor: 'background.paper', 
      borderRadius:'4px', 
      boxShadow: 24, 
      p: 4, 
      height:"600px",
    
      backgroundColor:"#fff",
      [theme.breakpoints.down("sm")]: {
        height:"300px",
       // fontSize: "16px",
        }
  },

  container: {
    paddingTop: "80px",
    paddingBottom: "30px",
   
  
    [theme.breakpoints.down("sm")]: {
      paddingTop: "50px",
    },
  },
  footerContainer: {
   // paddingTop: "60px",
   // paddingBottom: "106px",
    backgroundColor: "#8ac5cc",
    height:"550px",
color:"#fff",
  
    [theme.breakpoints.down("sm")]: {
      paddingTop: "50px",
      height:"100%",
    },
  },
  sectionHeaderContainer:{
    zIndex: 2,
    maxWidth: "59.72em",
    marginLeft: "auto",
    marginRight: "auto",
    position: "relative",
    display: "flex",
    marginBottom: "2.78em",
    
    justifyContent: "center",
    flexDirection: "column",
    alignitems: "center",
    /* margin-bottom: 2.78em; */
    /* display: flex;*/
    [theme.breakpoints.down("sm")]: {
      marginBottom: 0,
     },
  },
  sectionHeader:{
    textAlign: "center", 
    width:"100%",
    paddingTop:"30px",
    marginTop: 0,
    fontFamily: "Poppins-Bold",
    marginBottom: 0,
    fontSize: "54px",
    fontWeight: 700,
    lineHeight: 1.13,
    [theme.breakpoints.down("sm")]: {
     fontSize: "8vw",
    },
  },
  sectionSubHead:{
    borderBottom: "2px solid #C4D82E",
    color: "#000",
    cursor: "pointer",
    marginTop: "20px",
    marginLeft: "auto",
    marginRight: "auto",
    paddingBottom: "4px",
    fontSize: "14px",
    fontWeight: 700,
    textDecoration: "none",
    display: "block",
  },
  bigText:{
    fontSize: "1.67em",
    fontFamily: "Poppins-Bold",
    fontWeight: 700,
    lineHeight: 1.25,
    [theme.breakpoints.down("sm")]: {
      fontSize: "18px",
    },
  },

  //HOw IT WORKS SECTION
  hiwContainer:{
    height: "fit-content",
    width:'100%', 
    backgroundColor:"#E5F7E3", 
    paddingTop: "3.33em", 
    paddingBottom: "3.33em",
    fontFamily: "Poppins",
  },
  hiwBoxContainer:{
    display: 'flex', 
    flexDirection: 'row', 
    alignItems: 'center',
  },
  hiwSpan:{
    marginRight:'40px'
  },
  hiwNumber:{
    height:'60px',width:'60px'
  },
  hiwTextContainer:{
    display: 'flex', 
    flexDirection: 'column',
  },
  hiwTextHeader:{
    fontFamily:'Poppins-Bold'
  },
  hiwTextBody:{
    fontFamily:'Poppins'
  },
  hiwMedia:{
    display: 'block',
    margin: 'auto',
    height:'100%', 
    width:"100%",
  },
  exampleMedia:{
    display: 'block',
    margin: 'auto',
    height:'600px', 
    width:"100%",
  },
  compareChart:{
    width:"80%",
    [theme.breakpoints.down("md")]: {
      width:"100%",
    },
  },
  stepBottom:{
    textAlign: "center",
    justifyContent: "center",
    marginTop: "1em",
   
    display: "flex",
    [theme.breakpoints.down("sm")]: {

      paddingLeft: "2.78em",
      paddingRight: "2.78em",
    },
  },
  stepTop:{
    width: '10.31em',
    // height: 'auto',
     justifyContent: "center",
     alignItems: "flex-end",
     marginLeft: "auto",
     marginRight: "auto",
     display: "flex",
     [theme.breakpoints.down("sm")]: {
      width:'70%',
      height: "auto",
    },
  },
  hideLogo:{
    [theme.breakpoints.down("sm")]: {
     display:"none !important"
    },
  },
  mainHeading: {
    //filter: "drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.6))",
   
   // paddingRight: "20px",
    fontSize: "70px",
    fontWeight:700,
    fontFamily: "Poppins-Bold",
    color: "#000",
    lineHeight: "1",
    letterSpacing:"revert",
   // width:"85%",
    [theme.breakpoints.down("md")]: {
      fontSize: "52px",
      width:"100% !Important",
    // paddingLeft: "0px !important",
     // textAlign: "center",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "10vw",
      textAlign: "center",
      width:"100%",
      lineHeight: "1.2",
    },
  },
  heading: {
    //filter: "drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.6))",
   
    paddingRight: "20px",
    fontSize: "55px",
    fontFamily: "Arvo-Bold",
    color: "#544F55",
    width:"85%",
    [theme.breakpoints.down("md")]: {
      fontSize: "35px",
      width:"100% !Important",
    // paddingLeft: "0px !important",
     // textAlign: "center",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "30px",
      textAlign: "center",
      width:"100%",
    },
  },
  headerFooter: {
    //filter: "drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.6))",
   
   // paddingRight: "20px",
    fontSize: "24px",
    width:"90%",
    color:"#fff",
    fontWeight:400,
    fontFamily:"Lato",
    [theme.breakpoints.down("md")]: {
      fontSize: "24px",
      width:"100%",
     // textAlign: "center",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "20px",
      width:"100%",
      textAlign: "center",
    },
  },
  mainSubHeading: {
    // filter: "drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.6))",
    maxWidth:"25em",
    color: "#000",
    marginTop: "1em",
    marginBottom: "1.43em",
    lineHeight:"1.2",
    fontFamily:"Inter",
    fontSize: "1.94em",
     [theme.breakpoints.down("md")]: {
       fontSize: "18px !Important",
      // width:"90% !Important",
       //textAlign: "center",
     },
     [theme.breakpoints.down("sm")]: {
       fontSize: "20px !Important ",
       textAlign: "center",
       width:"auto",
       maxWidth:"none",
       paddingRight: "0px !Important",
     },
   },
  subHeading: {

   marginTop: "0",
   marginBottom: "10px",

   fontFamily: "Inter, sans-serif",
   fontSize: "18px",
   lineHeight: "1.44",
  },
  engagementContainer: {
    flexDirection: " column",
    justifyContent: " center",
    fontFamily: "Poppins-Bold",
    alignItems: " center",
    paddingLeft: " 12.5em",
    paddingRight: " 12.5em",
    display: " flex",
    width: "100%",
    maxWidth: "1920px",
    marginLeft: "auto",
    marginRight: "auto",
    paddingLeft: "13.3em",
    paddingRight: "13.3em",
    display: "block",
    position: "relative",

    [theme.breakpoints.down("sm")]: {
    overflow:"hidden",
    paddingLeft: '5%',
    paddingRight: '5%',

    },

  },
  engagementWrapper: {
    alignItems: "flex-start",
    marginBottom: "8.33em",
    justifyContent: "flex-end",
    alignItems: "flex-end",
    width: "100%",
    marginBottom: "10em",
    display: "flex",
    [theme.breakpoints.down("sm")]: {
     width:"100%",
     marginBottom: '60px',
     marginLeft: 'auto',
     marginRight: 'auto',
     flexDirection: 'column',
      },
  },
  engagementImageContainer:{
    justifyContent: "space-between",
    alignSelf: "flex-start",
    alignItems: "center",
    width: "50%",
    paddingLeft: "0",
    paddingRight: "0",
    display: "flex",

    [theme.breakpoints.down("md")]: {
      order: '-1',
      marginBottom: '30px',
      width: '100%',
       },
  
  },
  engagementImageWrapper:{
    justifyContent: "flex-start",
    alignItems: "flex-start",
    display: "flex",
    width: "100%",

  },
  engagementImage:{
    width: "29.17em",

    [theme.breakpoints.down("md")]: {
        marginLeft: 'auto',
        marginRight: 'auto',
        width: '100%',
       },
 
  },

  engagementTextContainer:{
    justifyContent: "flex-start",
    alignSelf: "stretch",
    aligItems: "center",

    justifyContent: "space-between",
    alignSelf: "flex-start",
    alignItems: "center",
    width: "50%",
    paddingLeft: "0",
    paddingRight: "0",
    display: "flex",


    [theme.breakpoints.down("sm")]: {
      width:"100%",
     
       },
  
  },
  titleContainer:{
    [theme.breakpoints.down("sm")]: {
      width:"100%",
     marginBottom: "0"
       },
  },
  title:{
    marginBottom: "30px",
    fontSize: "40px",
    fontWeight: "700",
    marginTop: "0",
    marginBottom: ".5em",
  //  fontSize: "2.78em",
    lineHeight: "1.2",
    fontFamily: "Poppins-Bold",

    [theme.breakpoints.down("sm")]: {
    //  textAlign: 'center',
    //  fontSize: '8vw',
       },
       [theme.breakpoints.down("md")]: {
        fontSize: '32px',
        textAlign: 'center',
         },
  
  },
  subHeaderFooter: {
    // filter: "drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.6))",
    width:"90%",
    color:"#fff",
    fontWeight:400,
    fontSize: "18px",
    fontFamily:"Lato",
     [theme.breakpoints.down("md")]: {
       fontSize: "20px",
       //textAlign: "center",
     },
     [theme.breakpoints.down("sm")]: {
       fontSize: "15px",
       textAlign: "center",
       width:"100%",
     },
   },
   
  rightTextContainer:{
  
   [theme.breakpoints.down("md")]: {
    paddingLeft:"60px !Important",
    paddingRight:"50px !Important"
   },
   [theme.breakpoints.down("sm")]: {
    paddingLeft:"0px !Important",
    paddingRight:"0px !Important"
   },
  },
 newsSection:{
  paddingLeft: "128px", 
  paddingRight: "128px",

  [theme.breakpoints.down("md")]: {
    paddingLeft: "1.5em",
    paddingRight: "1.5em",
  },
  [theme.breakpoints.down("sm")]: {
    paddingLeft: "1.5em",
    paddingRight: "1.5em",
  },
 },
 newsTileContainer:{
  [theme.breakpoints.down("md")]: {

  },
  [theme.breakpoints.down("sm")]: {
   justifyContent: "normal !Important",
   margin:"0px !Important",
  },
  
 },
  iPhone: {
    width: "100%",
  },
  imageTiles: {
    width: "100%",
 
  },

  //BRANDS SECTION
  brandsContainer: {
    margin: "0 auto",  display: "flex",
    justifyContent: "center", 
alignItems: "center"
  },
  brandHeader: {
    marginBottom: theme.spacing(1),
    fontFamily: "Poppins-bold",
  textAlign: "center",

  },
  brandSubheader: {
    marginBottom: theme.spacing(3),
    fontFamily: "Poppins",
  },
  gridImage:{
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    padding: theme.spacing(3),
  
  
},
row: {
  display: 'flex',
  justifyContent: 'center',
  marginBottom: theme.spacing(2.5), 
  [theme.breakpoints.down("sm")]: {
   display: 'grid',
}
},
step1media:{
  [theme.breakpoints.down("sm")]: {
  order:1,
  height:"100%"
 }
},
step1text:{
  [theme.breakpoints.down("sm")]: {
  order:2
 }
},
step2media:{
  [theme.breakpoints.down("sm")]: {
  order:3
 }
},
step2text:{
  [theme.breakpoints.down("sm")]: {
  order:4
 }
},
step3media:{
  [theme.breakpoints.down("sm")]: {
  order:5
 }
},
step3text:{
  [theme.breakpoints.down("sm")]: {
  order:6
 }
},
step4media:{
  [theme.breakpoints.down("sm")]: {
  order:7
 }
},
step4text:{
  [theme.breakpoints.down("sm")]: {
  order:8
 }
},
step5media:{
  [theme.breakpoints.down("sm")]: {
  order:9
 }
},
step5text:{
  [theme.breakpoints.down("sm")]: {
  order:10
 }
},


avatar: {
  margin: theme.spacing(1.25), // equivalent to 10px if the default spacing is 8px
    width: 150, // 150px width
    height: 150, // 150px height
},
//--------------------------------

  sectionContainer:{
    paddingTop: '8.33em',
    paddingBottom: '8.33em',
    [theme.breakpoints.down("sm")]: {
      overflow: "hidden",
      paddingTop: "60px",
      paddingBottom: "60px",
  }
     
  },


  mainLeftContainer: {
   //paddingLeft: "4em",
    paddingRight: "2em",
    [theme.breakpoints.down("md")]: {
    //  paddingLeft: "100px !Important",
      paddingRight: "0",
    },
  
    [theme.breakpoints.down("sm")]: {
      paddingLeft: "1.5em !Important",
      paddingRight: "1.5em",

    },
  },
  mainLeftContainerFooter: {
    color:"#fff",
   height:"100%",
    paddingLeft: "20em",
     paddingRight: "6em",
     [theme.breakpoints.down("md")]: {
       paddingLeft: "1.5em !Important",
       paddingRight: "1.5em !Important",
     },
     [theme.breakpoints.down("sm")]: {
       paddingLeft: "1.5em !Important",
       paddingRight: "1.5em !Important",
     },
   },
  mainRightContainer: {

   //paddingLeft: "2em",
    //paddingRight: "4em",
    [theme.breakpoints.down("md")]: {
     // paddingLeft: "1.5em !Important",
    //  paddingRight: "1.5em !Important",
    },
    [theme.breakpoints.down("sm")]: {
      paddingTop: "1.5em",
      height:"35em !important",
     // paddingLeft: "1.5em !Important",
     // paddingRight: "1.5em !Important",
     marginRight: "auto",
     marginLeft: "auto",
    },
  },
  mainRightContainerFooter: {
    paddingLeft: "2em",
    height:"100%",
     //paddingRight: "4em",
     [theme.breakpoints.down("md")]: {
       paddingLeft: "1.5em",
      // paddingRight: "1.5em",
     },
     [theme.breakpoints.down("sm")]: {
       paddingTop: "1.5em",
       paddingLeft: "1.5em",
     paddingRight: "1.5em",
     alignItems:"center"
     },
   },
   /*
  testimonalContainer: {
    backgroundColor: "#8AC5CC",
    paddingTop: "66px",
    paddingLeft: "140px",
    paddingRight: "140px",
    [theme.breakpoints.down("sm")]: {
      paddingLeft: "30px",
      paddingRight: "30px",
    },
  },*/

}));

const testimonals = [
  {
    id: 0,
    image: card1Image,
    info:
      "I think this will be good for micro influencers.",
    rating: 4,
    name: "Kyle Jones",
    type: "Influencer",
  },
  {
    id: 1,
    image: card2Image,
    info:
      "This app will be great with helping businesses market with influencers. Game-changer.",
    rating: 4,
    name: "Evan Cooper",
    type: "Influencer",
  },
  {
    id: 2,
    image: card3Image,
    info:
      "I would recommend this for any business considering influencer marketing on a budget. You only pay when it works.",
    rating: 4,
    name: "Eduardo Watson",
    type: "Merchant",
  },
];
 const typographyValues = ['"After burning our fingers with a few impersonal influencer platforms, Klippit was a game-changer. Their dedicated account manager wasnt just responsive, they anticipated our needs and proactively addressed them. It felt like they were truly invested in the success of our campaign, which ultimately led to fantastic results."', 
 
 '"Klippit is a great platform for influencer marketing campaigns that use coupons. It makes it easy for influencers to turn coupons into a marketing tool and track their results."', 
 
 '"Klippit is a breath of fresh air for influencer marketing! It solves the age-old problem of tracking coupon effectiveness with influencers. Their platform seems streamlined and influencer-friendly, making it easy to create engaging campaigns with measurable results. This is a win-win for brands and influencers alike!"'];

const Home = () => {
  const classes = useStyles();
  const theme = useTheme();
  const matchesSM = useMediaQuery(theme.breakpoints.down("sm"));
  const history = useHistory();
  const [open, setOpen] = useState(false);

  const handleClick = () => {
    setOpen(true);

    if (window.innerWidth >= 1280) { // "large" breakpoint in Material-UI
      setOpen(true);
    } else {
      window.open('https://calendly.com/klippit/klippit-merchant-onboarding/30min', '_blank');
    }
  };
  
  

  const handleClose = () => {
    setOpen(false);
  };
  const logos = [
    brand1, brand2, brand3, brand4, brand5, 
    brand6, brand7, brand8
  ];
  

  // Define 
 
    const [typographyIndex, setTypographyIndex] = useState(0); // Initialize state variable with index 0
  
    // Function to handle click event and update typography index
    const handleCaretClick = () => {
      setTypographyIndex((typographyIndex + 1) % typographyValues.length); // Update typography index, looping back to 0 when reaching the end
    }

    const StyledSpan = styled(Typography)(({ theme }) => ({
      display: 'inline-block',
      width: theme.spacing(8),
      height: theme.spacing(8),
      borderRadius: '50%',
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.background.default,
      textAlign: 'center',
      lineHeight: theme.spacing(8),
    }));

    const hash = window.location.hash;
    if (hash) {
      scroller.scrollTo(hash.substring(1), {
        duration: 800,
        delay: 0,
        smooth: 'easeInOutQuart',
      });
    }

  return (
   <> <Grid id="home" container alignItems={"center"} direction={"column"} //className={classes.pageStyle}
   >
      {/*PHOTO TILES*/}
      <Grid
        item
        container
        direction={matchesSM ? "column" : "row"}
        className={`${classes.container} ${classes.pageStyle} ${classes.heroContainer}`
        }
      >
        <Grid
          item
         /* lg={7}
          md={7}
          sm={12}
          xs={12}*/
          className={`${classes.heroWrapper} ${classes.heroWrapperText} `}
        >
          <Grid
            item
            container
            alignItems={matchesSM ? null : "flex-end"}
            direction={"column"}
          >
            <Grid item>
              <Grid
                item
                container
                direction={"column"}
                alignItems={matchesSM ? "center" : null}
              >
                <Grid item>
                  <Typography variant={"h1"} className={classes.mainHeading}>
                  Turn Customers into your Content Creators to Boost Sales
                       </Typography>
                </Grid>

                <Grid item style={{ marginTop: "42px" }}>
                  <Typography variant={"h5"} className={classes.mainSubHeading}>
                  Sign up for a free trial and get 1 free influencer shout-out from a local influencer in your city today!
                  </Typography>
                </Grid>
                <Grid item >
                  <GetStartedButton variant="contained"
                  className={classes.bookButton}
                  //  style={{ top: 0 }}
                  onClick={() => {
                    history.push("/pricing#spark");
                }}     

                /*    onClick={() => window.location.href = 'https://calendly.com/klippit/klippit-merchant-onboarding/30min'}*/>
                    Get Started
                  </GetStartedButton>
                  <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"

      >
        <div className={classes.modalContainer} >
          <IconButton aria-label="close" onClick={handleClose} style={{ position: 'absolute', right: 0, top: 0 }}>
            <CloseIcon />
          </IconButton>
          <iframe src="https://calendly.com/klippit/klippit-merchant-onboarding/30min" width="100%" height="100%" frameborder="0"></iframe>
        </div>
      </Modal>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Grid
          item
        /*  lg={5}
          md={5}
          sm={12}
          xs={12}*/
          className={`${classes.heroWrapperImage} `}
         // style={{ height:"700px"}}
        >
       { /*</Grid>  <img
            src={homeGif2}
            alt="Square Tiles"
            className={classes.imageTiles}
            style={{height:"100%", width:"100%", objectFit:"contain"}} />*/}
            <div className={classes.videoContainer} style={{position:"relative"}}>
            <video className={classes.videoStyle} id="home-video" autoPlay loop muted playsInline ><source src={homeVideo} type="video/mp4"/></video>
            
            </div>
         {/*   <video autoplay loop muted playsinline>
  <source src={homeVideo} type="video/mp4"/>
  Your browser does not support the video tag.
</video>*/}
        </Grid>
      </Grid>

         {/*TESTIMONIALS*/}

  

        <Grid 
        container
        item
          style={{ height: "fit-content", backgroundColor:"#FFE4BD",  }}>
     

     <Grid
        item
        direction={matchesSM ? "column" : "row"}
        className={classes.brandsContainer}
      >
<Box  className={classes.gridImage} style={{backgroundColor: "#FFE4BD"}}>
      <Typography variant="h4" className={classes.brandHeader}>
        Trusted by dozens of brands
      </Typography>
      <Typography variant="h6" className={classes.brandSubheaderubheader}>
        Across the US
      </Typography>
      <Box className={classes.row}>
        {logos.slice(0, 5).map((logo, index) => (
          <Avatar key={index} src={logo} alt={`logo${index + 1}`} className={classes.avatar} />
        ))}
      </Box>
      <Box  className={`${classes.row} ${classes.hideLogo} `}>
        {logos.slice(5, 8).map((logo, index) => (
          <Avatar key={index} src={logo} alt={`logo${index + 6}`} className={classes.avatar} />
        ))}
      </Box>
    </Box>
</Grid>
</Grid>
    
    <Grid id="hiw" className={classes.hiwContainer}>
   
 {/*How It Works */}
 <Container maxWidth="lg" style={{ paddingLeft:"2em", paddingRight:"2em"}}>
      <Box sx={{ textAlign: 'center', marginBottom:'40px' }}>
        <Typography variant="h1" style={{fontFamily:'Poppins-Bold'}} gutterBottom>
          How it Works
        </Typography>
        <Typography variant="body1" gutterBottom>
          Our Automated Influencer Marketing Process is designed to Skyrocket Your Sales within just 30 days.
        </Typography>
      </Box>
      <Grid container spacing={4}>
        {/* Step 1 */}
        <Grid item xs={12} md={6} style={{paddingRight:'10%', display:"flex", }} className={classes.step1text}>
          <Box className={classes.hiwBoxContainer} >
            <span className={classes.hiwSpan}>
              <img className={classes.hiwNumber} src={number1} alt="Step 1" />  </span>
          <Grid className={classes.hiwTextContainer}>
            <Typography variant="h4" gutterBottom className={classes.hiwTextHeader}>
              Free Influencer Shoutout
            </Typography>
            <Typography className={classes.hiwTextBody} variant="body1" gutterBottom>
              Within 3-4 days, Klippit sources a local influencer to promote your business and kickoff your campaign.
            </Typography>
            </Grid>
          
          </Box>
        </Grid>
        <Grid item xs={12} md={6} className={classes.step1media}>
        <video style={{ width:"50%", height:"75%"}} className={classes.hiwMedia} id="step1-video" autoPlay loop muted playsInline ><source src={step1} type="video/mp4"/></video>
         </Grid>
        {/* Step 2 */}
         <Grid item xs={12} md={6} className={classes.step2media}>
       <img src={step2} alt="Step 2" className={classes.hiwMedia} style={{ width:"50%"}} />
         </Grid>
         <Grid item xs={12} md={6} style={{ display:"flex"}} className={classes.step2text}>
          <Box className={classes.hiwBoxContainer} >
            <span className={classes.hiwSpan}>
              <img className={classes.hiwNumber} src={number2} alt="Step 2" />  </span>
          <Grid className={classes.hiwTextContainer}>
            <Typography variant="h4" gutterBottom className={classes.hiwTextHeader}>
            Promotion is created to convert
new and current customers
            </Typography>
            <Typography className={classes.hiwTextBody} variant="body1" gutterBottom>
            A default promotion offering a 10% discount is
automatically generated for your customers,
accessible only when they create content for
your business and share it with their friends.
            </Typography>
            </Grid>
          
          </Box>
        </Grid>
         {/* Step 3 */}
         <Grid item xs={12} md={6} style={{paddingRight:'10%', display:"flex"}} className={classes.step3text}>
          <Box className={classes.hiwBoxContainer} >
            <span className={classes.hiwSpan}>
              <img className={classes.hiwNumber} src={number3} alt="Step 1" />  </span>
          <Grid className={classes.hiwTextContainer}>
            <Typography variant="h4" gutterBottom className={classes.hiwTextHeader}>
            Klippit Package Arrives in the Mail            </Typography>
            <Typography className={classes.hiwTextBody} variant="body1" gutterBottom>
            Within 5-7 days, You'll receive pre-programmed
            QR code stickers and Klippit Near Field
            Communication (NFC) stickers. These NFC stickers
            leverage wireless technology to alert nearby
              customers.
            </Typography>
            </Grid>
          
          </Box>
        </Grid>
        <Grid item xs={12} md={6} className={classes.step3media}>
        <img src={step3} alt="Step 3" className={classes.hiwMedia} style={{ width:"50%"}} />
         </Grid>
          {/* Step 4 */}
          <Grid item xs={12} md={6} className={classes.step4media}>
          <video style={{ width:"50%"}} className={classes.hiwMedia} id="step4-video" autoPlay loop muted playsInline ><source src={step4} type="video/mp4"/></video>
         </Grid>
         <Grid item xs={12} md={6} style={{ display:"flex"}} className={classes.step4text}>
          <Box className={classes.hiwBoxContainer} >
            <span className={classes.hiwSpan}>
              <img className={classes.hiwNumber} src={number4} alt="Step 4" />  </span>
          <Grid className={classes.hiwTextContainer}>
            <Typography variant="h4" gutterBottom className={classes.hiwTextHeader}>
            Customers Become Content
Creators
            </Typography>
            <Typography className={classes.hiwTextBody} variant="body1" gutterBottom>
            Your customers are instantly notified of your
promotion, either by proximity or by scanning
your QR code, and they become your influencers
by creating content for your business and sharing
it with friends to unlock the discount.
            </Typography>
            </Grid>
          
          </Box>
        </Grid>

           {/* Step 5 */}
        <Grid item xs={12} md={6} style={{paddingRight:'10%', display:"flex"}} className={classes.step5text}>
          <Box className={classes.hiwBoxContainer} >
            <span className={classes.hiwSpan}>
              <img className={classes.hiwNumber} src={number5} alt="Step 5" />  </span>
          <Grid className={classes.hiwTextContainer}>
            <Typography variant="h4" gutterBottom className={classes.hiwTextHeader}>
            Viral Loop Created by Customers
          </Typography>
            <Typography className={classes.hiwTextBody} variant="body1" gutterBottom>
            We initiate a viral loop by incentivizing your
customers, now transformed into content creators,
with cash rewards for every new customer they
bring to your business. It's a win-win scenario for
everyone involved.
            </Typography>
            </Grid>
          
          </Box>
        </Grid>
        <Grid item xs={12} md={6} className={classes.step5media}>
        <img src={step5} alt="Step 5" className={classes.hiwMedia} style={{ width:"60%"}} />
         </Grid>
      </Grid>
    </Container>
</Grid>


      {/*Heavy Lift*/}
      <Grid
        container
       
        direction={"column"}
        className={classes.sectionContainer}
        maxWidth="lg" style={{ padding:"2em"}}>

        <Grid container spacing={4}>
        <Grid item xs={12} md={6}>
        <img src={heavyLift} alt="Heavy Lift"  style={{ width:"100%"}} />
         </Grid>
              <Grid item xs={12} md={6} style={{display:"flex", alignItems:"center", padding:"8%"}}>
              <Grid className={classes.hiwTextContainer}>
            <Typography variant="h4" gutterBottom className={classes.hiwTextHeader}>
            We do the heavy lifting for you
while you monitor from your
Dashboard
            </Typography>
            <Typography className={classes.hiwTextBody} variant="body1" gutterBottom>
            Effortlessly oversee your campaign, review customer
content, make campaign adjustments, and monitor their
performance all within the convenient confines of the
merchant portal.
            </Typography>
            </Grid>
              </Grid>
          </Grid>

  </Grid>

  {/*Compare*/}
<Grid  style={{ padding:"6em 2em 0px"}}>
<Box sx={{ textAlign: 'center', marginBottom:'40px' }}>
        <Typography variant="h1" style={{fontFamily:'Poppins-Bold'}} gutterBottom>
        Boost your social media and physical traffic
        </Typography>
        <Typography variant="body1" gutterBottom>
        Leveraging amazing social media influencers doesn’t have to be hard
        </Typography>
      </Box>

  <Grid>
  <img src={compareChart} alt="Compare Chart"  className={`${classes.hiwMedia} ${classes.compareChart} `} />
  </Grid>
</Grid>

 {/*EXAMPLES*/}
<Grid  style={{ padding:"6em 2em 0px"}}>
<Box sx={{ textAlign: 'center', marginBottom:'40px' }}>
        <Typography variant="h1" style={{fontFamily:'Poppins-Bold'}} gutterBottom>
        Here are examples of our recent collabs
        </Typography>
       
      </Box>

      <Grid container spacing={6}>
      <Grid item xs={12} md={4} >
      <a href="https://www.instagram.com/reel/Cr_YK4Jsl7Y/" target="_blank" class="example-item w-inline-block">
     <div className={classes.exampleMedia} style={{backgroundColor:"#dbeceb", transform: "rotate(3deg)", borderRadius:"2em"}}>  
     <img src={example1} alt="Example 1" className={classes.exampleMedia} style={{ width:"100%", transform: "rotate(-3deg)", borderRadius:"2em"}} />
       </div>

    
     {/* <Grid >
        <Typography style={{fontWeight:700}}>
        Bite Food & Coffee
        </Typography>
        <Typography>
        360 Essex St, Hackensack, NJ
          </Typography>
        </Grid>*/}
        </a>
        </Grid> 
        <Grid item xs={12} md={4} >
        <a href="https://www.instagram.com/reel/CrxQF2XJ4E8/" target="_blank" class="example-item w-inline-block">
     <div className={classes.exampleMedia} style={{backgroundColor:"#dbeceb", transform: "rotate(3deg)", borderRadius:"2em"}}>  
     <img src={example2} alt="Example 2" className={classes.exampleMedia} style={{ width:"100%", transform: "rotate(-3deg)", borderRadius:"2em"}} />
       </div>
       </a>
        </Grid>
        <Grid item xs={12} md={4} >
        <a href="https://www.tiktok.com/@oaklandroots/video/7197477057109085486" target="_blank" class="example-item w-inline-block">
     <div className={classes.exampleMedia} style={{backgroundColor:"#dbeceb", transform: "rotate(3deg)", borderRadius:"2em"}}>  
     <img src={example3} alt="Example 3" className={classes.exampleMedia} style={{ width:"100%", transform: "rotate(-3deg)", borderRadius:"2em"}} />
       </div>
       </a>
        </Grid>
      </Grid>
 </Grid>

 <Grid  style={{ padding:"6em 2em"}}>
<Box sx={{ textAlign: 'center', marginBottom:'40px' }}>
        <Typography variant="h1" style={{fontFamily:'Poppins-Bold'}} gutterBottom>
        Ready to Start?
        </Typography>
       
      </Box>
 <Grid item >
                  <GetStartedButton variant="contained"
                  className={classes.bookButton}
                    style={{ marginLeft: "auto", marginRight: "auto" }}
                    onClick={() => {
                      history.push("pricing#spark");
                  }}  

                /*    onClick={() => window.location.href = 'https://calendly.com/klippit/klippit-merchant-onboarding/30min'}*/>
                   Get Started
                  </GetStartedButton>
            
                </Grid>
             
    </Grid>
    <Grid style={{ position: 'fixed', top: '50%', width: '100%', textAlign: 'right'}}onClick={() => {
                     window.scrollTo({top: 0, left: 0, behavior: 'smooth' });

                  }}  >
      <Grid style={{backgroundColor:'#F0F0F0', borderRadius:"50%", margin: '30px', width: "30px", textAlign: "center", right: "0", position: "fixed"}}>
      <svg xmlns="http://www.w3.org/2000/svg" enable-background="new 0 0 24 24" height="24px" viewBox="0 0 24 24" width="24px"  fill="#108DAA"><g><rect fill="none" height="24" width="24"/></g><g><g><polygon points="6,17.59 7.41,19 12,14.42 16.59,19 18,17.59 12,11.59"/><polygon points="6,11 7.41,12.41 12,7.83 16.59,12.41 18,11 12,5"/></g></g></svg>
    </Grid>
    </Grid>
    </Grid>
   
    </>
  );
};

export default Home;
