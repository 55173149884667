import React from "react";
import klippitLogo from "../assets/logo/klippit-icon.png";

const KlippitIcon = (props) => {
    const size = props.size || "150";
    return (
        <>
           <img 
               src={klippitLogo}
               alt="Klippit Logo"
               style={{ 
                   filter: "sepia(15%) saturate(100%) brightness(100%) hue-rotate(0deg)",
                   left: "50%",
                }}
                width={size}
           />
        </>
    )
}

export default KlippitIcon;
