import React from "react";
import { useState } from "react";
import { useHistory } from "react-router-dom";

import Button from "@material-ui/core/Button";
import TextField from '@material-ui/core/TextField';
import Box from '@material-ui/core/Box';
import CircularProgress from '@material-ui/core/CircularProgress';
import Alert from "@material-ui/lab/Alert";



const LoginForm = (props) => {
    const [credentials, setCredentials] = useState({email: "", pass: ""})
    const [email, setEmail] = useState("");
    const [pass, setPass] = useState("");

    const [loading, setLoading] = useState(false);
    const [showError, setShowError] = useState(false)
    const [errorMessage, setErrorMessage] = useState("")
    const timer = React.useRef();
    const history = useHistory();


    React.useEffect(() => {
      return () => {
        clearTimeout(timer.current);
      };
    }, []);

    async function loginPressed(e) {
        e.preventDefault();
        if (!loading) {
          setLoading(true);
        }

        try {
          const response = await fetch(
            `/api/v1/accounts/login?type=merchant`,
            {
              method: "POST",
              credentials: 'include',
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify({
                email: email,
                password: pass,
              }),
            }
          );
          //console.log("Response: " + response);
          const json = await response.json();
         // console.log("Message: " + responseData.message);

          if (!response.ok || json.status != 'success') {
            //setErrorMessage(responseData.message);
            setErrorMessage("Login failed. Please try again")
            setShowError(true);
            setTimeout(() => {
              setShowError(false);
            }, 4000);
           
          } else {
              console.log("success")
              window.location.href = `/merchant/overview`;
          }
        } catch (error) {
            console.log("Error: " + error);

           // setErrorMessage(error.message);
            setShowError(true);
        } finally {
          timer.current = window.setTimeout(() => {
            setLoading(false);
          }, 1000);
        }
    }

   
    return (
        <div style={{ width: '30vw', minWidth: '200px' }}>
            <div style={{ height: '30%', display: 'flex', flexDirection: "column", justifyContent: "center", alignItems: 'center'}}>
                <h3 style={{ margin: "8px" }}>Merchant Dashboard</h3>
                {showError
                    ?
                        <Alert severity="error">{errorMessage}</Alert>
                    :
                    <></>
                }
                <form style={{ width: '100%'}} onSubmit={loginPressed}> 
                <div style={{ marginTop: "8px", width: '100%', display: 'flex', flexDirection: "column", justifyContent: "center"}}>
                    <TextField  size="small" id="outlined-basic-email" label="Email Address" variant="outlined" style={{ marginTop: "8px" }}
                        value={email}
                        onInput={e => setEmail(e.target.value)}
                        required
                     />
                    <TextField type="password" size="small" id="outlined-basic-pw" label="Password" variant="outlined" style={{ marginTop: "8px" }}
                        value={pass}
                        onInput={e => setPass(e.target.value)}
                        required
                     />
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <Box sx={{ m: 0, position: 'relative', width: '100%' }}>
                            <Button 
                                type="submit"
                                variant="contained" 
                                disabled={loading}
                                style={{ 
                                    width: '100%', 
                                    marginTop: "16px" , 
                                    backgroundColor: '#1190ad' , 
                                    color: '#fff' }}
                            >
                                    Login
                            </Button>
                            {loading && (
                                <CircularProgress
                                    size={24}
                                    style={{
                                      color: "#fff",
                                      position: 'absolute',
                                      top: '50%',
                                      left: '50%',
                                      marginTop: '-4px',
                                      marginLeft: '-12px',
                                    }}
                                  />
                                )}
                        </Box>
                    </Box>
                </div>
                
                </form>
                <Button 
                    variant="text" 
                    disabled={loading}
                    disableRipple
                    style={{
                        backgroundColor: "transparent",
                        marginTop: 16,
                        fontSize: 11,
                    }}
                    onClick={() => console.log("forgot")}
                >
                    Forgot Password?
                </Button>
                <Button 
                    variant="text" 
                    disabled={loading}
                    disableRipple
                    style={{
                        backgroundColor: "transparent",
                        marginTop: 8,
                        fontSize: 11,
                        color: '#1190ad',
                    }}
                    onClick={props.showSignupForm}
                >
                    Don't have an account? Sign up here
                </Button>
            </div>
        </div>
    )
}

export default LoginForm;
