import React, { Fragment, useState } from "react";
import useScrollTrigger from "@material-ui/core/useScrollTrigger";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Button from "@material-ui/core/Button";
import Modal from '@material-ui/core/Modal';
import CloseIcon from '@material-ui/icons/Close';
import {MenuItem,Menu,ListItemIcon, ListItemText} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import PhoneIcon from '@material-ui/icons/Phone';
import { Link } from "react-router-dom";
import { makeStyles, styled } from "@material-ui/styles";

import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";
import { scroller } from 'react-scroll';
import SwipeableDrawer from "@material-ui/core/SwipeableDrawer";
import MenuIcon from "@material-ui/icons/Menu";
import IconButton from "@material-ui/core/IconButton";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";

import Grid from "@material-ui/core/Grid";

import { useHistory } from "react-router-dom";

// NAV IMAGES
import klippitLogo from "../assets/logo/klippitLogo.png";
import klippitLogoInverted from "../assets/logo/klippitLogo-inverted.png";
import whyKlippit from "../assets/nav/whyKlippit.png";
import demo from "../assets/nav/demo.png";
import press from "../assets/nav/press.png";
import { ImportantDevices } from "@material-ui/icons";


/*const GetStartedButton = styled(Button)({
  boxShadow: 'none',
  textTransform: 'none',
  fontSize: 18,
  //padding: '6px 12px',
  border: '1px solid',
  lineHeight: 1.5,
  backgroundColor: '#fff',
  borderColor: '#fff',
  color: '#1190ad',
  borderRadius: "20px",
  fontFamily: "Lato-Bold",
  /*
  fontFamily: [
    '-apple-system',
    'BlinkMacSystemFont',
    '"Segoe UI"',
    'Roboto',
    '"Helvetica Neue"',
    'Arial',
    'sans-serif',
    '"Apple Color Emoji"',
    '"Segoe UI Emoji"',
    '"Segoe UI Symbol"',
  ].join(','),
  '&:hover': {
    backgroundColor: '#fff',
    boxShadow: 'none',
  },
  '&:active': {
    boxShadow: 'none',
    borderColor: '#1190ad',
  },
  '&:focus': {
    boxShadow: '0 0 0 0.2rem rgba(0,123,255,.5)',
  },
});*/
const GetStartedButton = styled(Button)({
  
   display: "-webkit-box",
   display: "-ms-flexbox",
   display: "flex",
   webkitBoxPack: "center",
   msFlexPack: "center",
   justifyContent: "center",
   webkitBoxAlign: "center",
   msFlexAlign: "center",
   alignItems: "center",
   width: "auto",
   height: "100%",
   paddingTop: "10px",
   paddingBottom: "10px",
   paddingLeft: "40px",
   paddingRight: "40px",
   color: "black",
   textDecoration: "none",
   textTransform:"none",
   fontSize:"16px",
   lineHeight:1,
   backgroundColor: '#C4D82E',
   fontFamily: "Poppins-Bold",
   "&:hover": {
     backgroundColor: 'black',
     color: 'white',
   },
 
 
 });
const useStyles = makeStyles((theme) => ({
 /* navBar: {
   // backgroundColor: theme.palette.primary.main,
   backgroundColor: "#fff",
    height: "80px",
    paddingLeft: "100px",
    paddingRight: "180px",
    borderBottom: "1px solid #108daa",
    [theme.breakpoints.down("md")]: {
      paddingLeft: "100px",
      paddingRight: "100px",
    },
    [theme.breakpoints.down("sm")]: {
      paddingLeft: 0,
      paddingRight: 0,
    },
    boxShadow: "0 2px 4px 0 rgba(0,0,0,.2)",
  },
*/
bookButton:{
  [theme.breakpoints.down("md")]: {
    height: '40px',
    paddingLeft: '20px',
    paddingRight: '20px',

    },
},
  navBar: {
    paddingTop:"16px",
    backgroundColor: "#fff",
    position: "fixed",
    top: "0%",
    bottom: "auto",
    left: "0%",
    right: "0%",
    zIndex: '1000',
    background: '#ddd',
  },
  navBarInnerContainer: {
    maxWidth: '1920px',
    height: '80px',
    padding: '0 5em',
    transition: 'height 1s',
    alignItems: 'center',
 //   padding: '1.35em 13.3em',
    display: 'flex !Important',
    position: 'relative',
  },
logoBig: {  
  width: '100%',
  height: 'auto',

},
navMenu: {
  justifyContent: " center",
  alignItems: "center",
  width: "100%",
  height: "100%",
  display: "flex",
  float: "right",
  position: "relative",
},
navMenuInner: {
  gridColumnGap: "2em",
  gridRowGap: "16px",
  gridTemplateRows: "auto",
  gridTemplateColumns: "1fr 1fr 1fr 1fr",
  gridAutoColumns: "1fr",
  alignItems: "center",
  display: "grid",
 
},
modalContainer:{
  position: 'absolute',
   top: '50%',
    left: '50%', 
    transform: 'translate(-50%, -50%)', 
    width: 400, 
    bgcolor: 'background.paper', 
    borderRadius:'4px', 
    boxShadow: 24, 
    p: 4, 
    height:"600px",
  
    backgroundColor:"#fff",
    [theme.breakpoints.down("sm")]: {
      height:"300px",
     // fontSize: "16px",
      }
},
container: {
  justifyContent: "space-between",
  width: "100%",
  maxWidth: "1920px",
  marginLeft: "auto",
  marginRight: "auto",
 // paddingLeft: "13.3em",
//  paddingRight: "13.3em",
  display: "block",
  position: "relative",
},
  navLogo: {
    zIndex: "5",
    width: "auto",
    height: "24px",
    position: "absolute",
    top: "50%",
    bottom: "auto",
    left: "auto",
    right: "auto",
    transform: "translateY(-50%)",
    cursor: 'pointer',
    paddingBottom: '0',
  },
  navBrand:{
    float: "left",
    color: "#333",
    textDecoration: "none",
    position: "relative",
  },
  navDropdown: {

  },
  navDropdownToggle: {

  },
  navLink: {
    marginLeft: "60px",
    alignContent:"center",
    cursor: "pointer",
  },
  navLinkText: {
    color: "#108daa", 
    fontSize: "18px", 
    top: 6,
    fontFamily:"Inter",
    padding:0,
    whiteSpace:"nowrap",
    textTransform: "none",
    fontWeight:600,
     "&:hover": {
      backgroundColor: "transparent",
    },
  },
  navButton: {
   // marginLeft: "auto",
  whiteSpace:"nowrap",
  marginLeft: "auto",
  
    cursor: "pointer",
  },
  logo: {
    cursor: 'pointer'
  },
  linkImg: {
    cursor: 'pointer'
  },
  button: {
    cursor: 'pointer'
  },

  drawer: {
    backgroundColor: theme.palette.primary.main,
    paddingTop: "1.5em",
  },
  appBar: {
    zIndex: theme.zIndex.modal + 1,
    backgroundColor: theme.palette.primary.main,
    paddingTop: "1em",
    paddingBottom: "1em",
   
  },

  toolbarMargin: {
    ...theme.mixins.toolbar,
    marginBottom: "1em",
    [theme.breakpoints.down("md")]: {
      marginBottom: "1em",
    },
  },

  drawerIconContainer: {
    marginLeft: "auto",
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
  drawerIcon: {
    color: theme.palette.common.white,
    height: "30px",
    width: "30px",
  },
  drawerItem: {
    paddingTop: "1.5em",
    paddingBottom: "1.5em",
  },
}));


function ElevationScroll(props) {
  const { children } = props;
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
  });

  return React.cloneElement(children, {
    elevation: trigger ? 4 : 0,
  });
}

const Header = () => {
  const classes = useStyles();
  const history = useHistory();
  const [anchorElForBusiness, setAnchorElForBusiness] = useState(null);
  const [anchorElForPartners, setAnchorElForPartners] = useState(null);
  const matchesSM = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const [open, setOpen] = useState(false);

  const handleClick = () => {
    setOpen(true);

    if (window.innerWidth >= 1280) { // "large" breakpoint in Material-UI
      setOpen(true);
    } else {
      window.open('https://calendly.com/klippit/klippit-merchant-onboarding/30min', '_blank');
    }
  };
  

  const handleClose = () => {
    setOpen(false);
  };
 

  const [openDrawer, setOpenDrawer] = useState(false);
 const handleMenuItemClick = (route) => {
    if (route.startsWith('/')) {
      history.push(route);
    } else {
      history.push('/');
      setTimeout(() => {
        scroller.scrollTo(route.substring(1), {
          duration: 800,
          delay: 0,
          smooth: 'easeInOutQuart',
        });
      }, 100);
    }
    setAnchorElForBusiness(null);
  };
  const handleBusinessMenuOpen = (event) => {
    setAnchorElForBusiness(event.currentTarget);
  };

  const handleBusinessMenuClose = () => {
    setAnchorElForBusiness(null);
  };

  const handlePartnersMenuOpen = (event) => {
    setAnchorElForPartners(event.currentTarget);
  };

  const handlePartnersMenuClose = () => {
    setAnchorElForPartners(null);
  };

  const handleCallButtonClick = () => {
    // Handle click logic for "Book a Call" button
  };

  const toggleDrawer = () => {
    setOpenDrawer((prevState) => {
      console.log('Previous state:', prevState);
      const newState = !prevState;
      console.log('New state:', newState);
      return newState;
    });
  };

  const handleDrawerClose = () => {
    setOpenDrawer(false);
  };

  return (
    <Fragment>
      <ElevationScroll>
        <AppBar position="fixed" className={classes.navBar}>
          <Toolbar>
            <Grid container alignItems="center" justify="space-between">
              <Grid item>
                <img src={klippitLogoInverted} alt="Klippit" className={classes.logo} onClick={() => history.push("/")} />
              </Grid>
              <Grid item>
                {matchesSM ? (
                     <><GetStartedButton
                    className={classes.bookButton}
                      style={{
                      color: '#000', fontSize:'12px',borderRadius:"8px",paddingTop:'8px', paddingBottom:'8px', fontWeight:700, }}
                      onClick={() => {
                        history.push("/pricing");
                    }}  
                  >
                    Get Started
                  </GetStartedButton>
                  
                { /* <IconButton onClick={toggleDrawer}>
                      <MenuIcon />
                    </IconButton>*/}</>
                ) : (
                  <Fragment >
                    <Grid style={{display:"flex"}}>
                    <Button aria-controls="business-menu" aria-haspopup="true" onClick={handleBusinessMenuOpen}>
                      For Businesses
                      <ExpandMoreIcon />
                    </Button>
                    <Menu
                      id="business-menu"
                      style={{top:'35px',boxShadow: "0 4px 20px rgba(0, 0, 0, .05", borderRadius:16}}
                      anchorEl={anchorElForBusiness}
                      keepMounted
                      open={Boolean(anchorElForBusiness)}
                      onClose={handleBusinessMenuClose}
                    >
                       <MenuItem onClick={() => handleMenuItemClick("#home")}>Get a free influencer</MenuItem>
                      <MenuItem  onClick={() => handleMenuItemClick("#hiw")}>How it works</MenuItem>
                      <MenuItem onClick={() => handleMenuItemClick("/pricing")}>Pricing</MenuItem>
                     <MenuItem onClick={() => {window.location.href="https://business.getklippit.com"}}>Login as Merchant</MenuItem>
                     
                    </Menu>
                    <Button aria-controls="partners-menu" aria-haspopup="true" onClick={handlePartnersMenuOpen}>
                      For Partners
                      <ExpandMoreIcon />
                    </Button>
                    <Menu
                    style={{top:'35px', boxShadow: "0 4px 20px rgba(0, 0, 0, .05", borderRadius:16}}
                      id="partners-menu"
                      anchorEl={anchorElForPartners}
                      keepMounted
                      open={Boolean(anchorElForPartners)}
                      onClose={handlePartnersMenuClose}
                    >
                      <MenuItem onClick={() => window.open("https://klippit.postaffiliatepro.com/affiliates/")}>Become a partner</MenuItem>
                     { /*<MenuItem onClick={handlePartnersMenuClose}>How to get started</MenuItem>*/}
                    </Menu>
                    <GetStartedButton 
                  className={classes.bookButton}
                  //  style={{ top: 0 }}
                 // onClick={handleClick}

                /*    onClick={() => window.location.href = 'https://calendly.com/klippit/klippit-merchant-onboarding/30min'}*/
                
                onClick={() => {
                  history.push("/pricing");
              }} >

               
                  Get Started
                  </GetStartedButton>
                  <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"

      >
        <div className={classes.modalContainer} >
          <IconButton aria-label="close" onClick={handleClose} style={{ position: 'absolute', right: 0, top: 0 }}>
            <CloseIcon />
          </IconButton>
          <iframe src="https://calendly.com/klippit/klippit-merchant-onboarding/30min" width="100%" height="100%" frameborder="0"></iframe>
        </div>
      </Modal>
      </Grid>
                  </Fragment>
                )}
              </Grid>
            </Grid>
          </Toolbar>
        </AppBar>
      </ElevationScroll>
      <SwipeableDrawer
        anchor="right"
        open={openDrawer}
        onClose={handleDrawerClose}
        onOpen={toggleDrawer}
      >
        {/* Add Drawer content here */}
      </SwipeableDrawer>
      <Toolbar /> {/* Placeholder for the fixed app bar */}
    </Fragment>
  );
};

export default Header;
