import React from "react";
import klippitIcon from "../assets/logo/klippit-icon.png";


const Divider = () => {
    const hrTheme = { display: "flex", width: "75vw" };
    const hrLine = {
        width: "100%",
        position: "relative",
        margin: "15px 1px",
        borderBottom: "1px solid #000",
    };
    const hrIcon = {
        position: "relative",
        color: "#ff0000",
        objectFit: "contain",
    };
    const img = {
        width: "64px",
        objectFit: "contain",
    }
 
    
    return (
        <div style={hrTheme}>
            <div style={hrLine}></div>
            <div style={hrIcon}><img style={img} src={klippitIcon} alt="klippit"/></div>
            <div style={hrLine}></div>
        </div>
    )
};

export default Divider;
