import React, { useEffect, useState } from "react";
import  blogs  from '../assets/blogs.json';
import { useHistory } from "react-router-dom";
const images = require.context('../assets/homePage', true);

const Blog = () => {
    const history = useHistory();
	const [blog, setBlog] = useState({});

	useEffect(() => {
		const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        const blogIdx = urlParams.get('a');
		if (isNaN(parseInt(blogIdx))) { history.push('/') }
        if (blogIdx > blogs.length || blogIdx === null) { history.push('/') }
        setBlog(blogs[parseInt(blogIdx)])
	}, [])

	return (
		<>
		<div style={{ width: "100vw", height: "95vh", margin: 'auto' }}>
                <div style={{ height: '100%', display: 'flex', flexWrap: 'nowrap', flexDirection: "column", alignItems: 'start',  overflow: 'scroll' ,margin: "32px 0px"}}>

				<div className="container" style={{ marginTop: "32px", paddingLeft: "96px", paddingRight: "96px", textAlign: 'left' }}>
					<h1 style={{ fontFamily: "Roboto-Lite", color: "#333" }}><b>{blog.title}</b></h1>
					<br />
					<h4 style={{ fontFamily: "Roboto-Lite", color: "#333" }}><b>{blog.subtitle}</b></h4>
					<br />
					{blog.imageUrl &&
						<img width="350" height="350" src={images(`${blog.imageUrl}`)} alt="Image" style={{ 
							objectFit: "cover" 
						}}/>
					}
					<div style={{ fontSize: "14px", marginTop: "32px" }} >
        				<span style={{whiteSpace: "pre-line"}}>{blog.body}</span>
        			</div>
        			<br />
        			<br />
				</div>
			</div>
		</div>	
		</>
	);
}

export default Blog;
