import React from "react";
import { useState } from "react";
import { useHistory } from "react-router-dom";

import KlippitIcon from "./KlippitIcon";


const Terms = () => {

	const termsText = `
Welcome to Klippit, an online platform that allows you to find and save deals and coupons from your favorite retailers. By accessing or using our services, you agree to be bound by the following terms and conditions:

General
a. Klippit is owned and operated by Klippit ltd. Company, which reserves the right to modify, suspend, or terminate the service or any part of it at any time without notice.

b. Klippit is intended for personal use only, and any commercial use or distribution of our content is strictly prohibited.

c. By using Klippit, you represent and warrant that you are at least 18 years old and that you have the legal capacity to enter into this agreement.

User Accounts
a. You must create an account with Klippit to use our services. You agree to provide accurate and complete information when registering and to update your information as needed.

b. You are responsible for maintaining the security of your account and password, and for any activity that occurs under your account.

c. You agree not to use another user's account without their permission, and to notify us immediately if you suspect any unauthorized use of your account.

Intellectual Property
a. All content on Klippit, including but not limited to text, graphics, logos, images, and software, is the property of Klippit ltd. Company or its licensors and is protected by copyright, trademark, and other intellectual property laws.

b. You may not copy, reproduce, distribute, or create derivative works from our content without our express written permission.

User Content
a. Klippit allows users to submit their own content, such as reviews and comments. By submitting content, you grant us a non-exclusive, royalty-free, perpetual, and worldwide license to use, modify, and distribute your content in any form or media.

b. You represent and warrant that your content is accurate, not confidential or proprietary, and does not violate any third-party rights or applicable laws.

c. We reserve the right to remove any user content that we deem inappropriate, offensive, or in violation of our policies.

Disclaimer of Warranties
a. Klippit provides its services on an "as is" and "as available" basis, without any warranties of any kind, express or implied, including but not limited to warranties of merchantability, fitness for a particular purpose, and non-infringement.

b. We do not warrant that our services will be uninterrupted or error-free, or that any defects will be corrected.

Limitation of Liability
a. To the maximum extent permitted by law, Klippit ltd. Company and its affiliates, officers, employees, agents, and licensors shall not be liable for any direct, indirect, incidental, special, consequential, or exemplary damages, including but not limited to damages for loss of profits, goodwill, use, data, or other intangible losses, resulting from your use of or inability to use Klippit or any of its services.

Indemnification
a. You agree to indemnify and hold harmless Klippit ltd. Company and its affiliates, officers, employees, agents, and licensors from any claims, damages, liabilities, and expenses (including attorneys' fees) arising from your use of Klippit or any breach of these terms and conditions.

Governing Law
a. These terms and conditions shall be governed by and construed in accordance with the laws of GA/USA, without giving effect to any principles of conflicts of law.

b. Any dispute arising from or relating to these terms and conditions shall be resolved through binding arbitration in Atlanta/GA/USA, in accordance with the rules of

	`

	return (
		<>
		<div style={{ width: "100vw", height: "95vh", margin: 'auto' }}>
                <div style={{ height: '100%', display: 'flex', flexWrap: 'nowrap', flexDirection: "column", alignItems: 'center',  overflow: 'scroll' ,margin: "32px 0px"}}>
		  			<div style={{ marginTop: '32px', padding: '4px', justifyContent: 'center', alignItems: 'center' }}>
						<KlippitIcon size={"80"}/>
					</div>
				<div className="container" style={{ marginTop: "32px", paddingLeft: "96px", paddingRight: "96px" }}>
					<h1 style={{ fontFamily: "Roboto-Lite", color: "#333" }}><b>Terms and Conditions</b></h1>
					<br />
					<div style={{ fontSize: "14px"}} >
						{termsText.split("\n\n").map((i,key) => {
            				return <div key={key}><br />{i}</div>;
        				})}
        			</div>
        			<br />
        			<br />
				</div>
			</div>
		</div>

		</>
	);
}

export default Terms;