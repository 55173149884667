import React, { Fragment } from "react";

import { makeStyles } from "@material-ui/styles";

import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";

import Grid from "@material-ui/core/Grid";

import { useHistory, Link } from "react-router-dom";

// FOOTER IMAGES
import whyKlippit from "../assets/footer/whyKlippit.png";
import theApp from "../assets/footer/theApp.png";
import blog from "../assets/footer/blog.png";
import copyright from "../assets/footer/copyright.png";
import facebook from "../assets/footer/facebook.png";
import linkedin from "../assets/footer/linkedin.png";
import twitter from "../assets/footer/twitter.png";
import instagram from "../assets/footer/instagram.png";
import { Typography } from "@material-ui/core";
import klippitLogoInverted from "../assets/logo/klippitLogo-inverted.png";
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import FacebookIcon from '@material-ui/icons/Facebook';
import InstagramIcon from '@material-ui/icons/Instagram';
import TwitterIcon from '@material-ui/icons/Twitter';
import CopyrightOutlinedIcon from '@material-ui/icons/CopyrightOutlined';

const useStyles = makeStyles((theme) => ({
  navBar: {
    backgroundColor: '#fff',
    borderTop: `2px solid ${theme.palette.primary.main}`,
    color: "#000",
    height: "auto",
    paddingTop:"70px",
    paddingLeft: "147px",
    paddingRight: "147px",
    [theme.breakpoints.down("md")]: {
      paddingLeft: "1.5em",
      paddingRight: "1.5em",
    },
  },
  footerTitle: {
    fontSize:'16px',
    fontWeight:"700",
    fontFamily: "Poppins-Bold",
  // cursor: "pointer",
    [theme.breakpoints.down("sm")]: {
     
    },
  },
  menuItem:{
    cursor: "pointer",
    textDecoration: "none",
    '& a':{
      textDecoration: "none",
      color: "#000",
    },
  },
  footerLinkList:{
  gridColumnGap: '10px',
  gridRowGap: '30px',
  gridTemplateRows: 'auto auto',
  gridTemplateColumns: '1fr',
  gridAutoColumns: '1fr',
  marginTop: '30px',
  marginBottom: 0,
  display: 'grid',

  },
  footerLink: {
    marginLeft: "60px",
    cursor: "pointer",
    [theme.breakpoints.down("md")]: {
      marginLeft: "50px",
    },
  },
  socialIconWrapper: {
    display:"inline-flex",
  //  marginLeft: "40px",
    [theme.breakpoints.down("sm")]: {
  //    marginLeft: "30px",
    },
  },
  socialIcon: {
paddingRight: "10px",

    [theme.breakpoints.down("sm")]: {
  //    width: "28px",
    },
  },
}));

const today = new Date();
const year = today.getFullYear();

const Header = () => {
  const classes = useStyles();
  const theme = useTheme();
  const matchesSM = useMediaQuery(theme.breakpoints.down("sm"));

  const history = useHistory();

 
  const columns = (
<Grid item container spacing={4}>
  <Grid  item xs={6} md={3} style={matchesSM ? null : { marginLeft: "auto" }}>
<Typography className={classes.footerTitle}>
  For Businesses
</Typography>

<Typography className={classes.footerLinkList} component="ul" style={{ listStyleType: 'none', paddingLeft: 0,fontFamily: "Inter", }}>

        <li className={classes.menuItem}>  
        <a
         
         href="/#hiw"
         
         rel="noopener noreferrer"
         >
    How it Works</a>
        </li>
          <li className={classes.menuItem}>   <a
         
         href="/pricing"
        
         rel="noopener noreferrer"
       >
      Pricing   </a> 
            </li>
            <li className={classes.menuItem}>   <a
         
         href="/login"
         
         rel="noopener noreferrer"
       >
    Login as Merchant </a> 
        </li>
       </Typography>

  </Grid>
  <Grid  item xs={6} md={3} style={matchesSM ? null : { marginLeft: "auto" }}>
  <Typography className={classes.footerTitle}>
  For Creators
</Typography>

<Typography className={classes.footerLinkList} component="ul" style={{ listStyleType: 'none', paddingLeft: 0,fontFamily: "Inter", }}>
    <li className={classes.menuItem}>   <a
         
         href="https://klippit.postaffiliatepro.com/affiliates/"
       // target={"_blank"}
         rel="noopener noreferrer"
       > 
      Join the list    </a>   
        </li>

       </Typography>

</Grid>
<Grid  item xs={6} md={3} style={matchesSM ? null : { marginLeft: "auto" }}>
<Typography className={classes.footerTitle}>
  Support
</Typography>

    <Typography className={classes.footerLinkList} component="ul" style={{ listStyleType: 'none', paddingLeft: 0,fontFamily: "Inter", }}>
    <li className={classes.menuItem}>   <a
         
         href="/privacy"
         target={"_blank"}
         rel="noopener noreferrer"
       > 
        Privacy Policy </a> 
        </li>
    <li className={classes.menuItem}>
    <a
         
          href="/terms"
          target={"_blank"}
          rel="noopener noreferrer"
        >  
       
       Terms  </a>
     </li>

  
         <li className={classes.menuItem}>   <a
         
         href="mailto:support@klippitapp.com"
         target={"_blank"}
         rel="noopener noreferrer"
       > 
        Contact Us  </a> 
              </li>
       </Typography>
    
</Grid>
<Grid  item xs={6} md={3} style={matchesSM ? null : { marginLeft: "auto" }}>
<Grid item>
                <img src={klippitLogoInverted} alt="Klippit" className={classes.logo} onClick={() => history.push("/")} />
              </Grid>
              <Typography className={classes.footerLinkList} component="ul" style={{ listStyleType: 'none', paddingLeft: 0,fontFamily: "Inter", }}>
    <li className={classes.menuItem}>  

    <p > <a className={classes.socialIconWrapper} href="https://www.linkedin.com/company/klippit-ltd-company" target={"_blank"}><LinkedInIcon className={classes.socialIcon}> </LinkedInIcon> LinkedIn</a> </p>
        </li>
     
        <li className={classes.menuItem}>  

<p > <a className={classes.socialIconWrapper} href="https://www.instagram.com/klippitkoupns/" target={"_blank"}><InstagramIcon className={classes.socialIcon}> </InstagramIcon> Instagram</a> </p>
    </li>

    <li className={classes.menuItem}>  

<p > <a className={classes.socialIconWrapper} href="https://twitter.com/OfficialKlippit" target={"_blank"}><TwitterIcon className={classes.socialIcon}> </TwitterIcon> Twitter</a> </p>
    </li>

    <li className={classes.menuItem}>  

<p > <a className={classes.socialIconWrapper} href="https://www.facebook.com/KlippitSavings/" target={"_blank"}><FacebookIcon className={classes.socialIcon}> </FacebookIcon> Facebook</a> </p>
    </li>
    <li className={classes.menuItem}>  

       

<Typography style={{color:"#666666", paddingTop:'10px'}}>
      <p className={classes.socialIconWrapper}>  <CopyrightOutlinedIcon/> {year} Klippit Ltd Corporation</p>
</Typography>
</li>
 </Typography>
</Grid>
</Grid>

  );

  return (
    <Grid item container alignItems={"center"} className={classes.navBar}>
      {columns}
    </Grid>
  );
};

export default Header;
